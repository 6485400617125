
export const regPermissionName = /^[\w:-]{1,255}$/;

export type ImportPermissionDto = {
  table?: string;

  name: string;
  display_name?: string;
  short_name?: string;
  description?: string;
};

export type ImportObjectPermissionDto = {
  table?: string;

  permission_name: string;
  is_allow?: string;
  start_date?: string;
  end_date?: string;
};
