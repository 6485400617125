import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { useQuery } from 'react-query';
import { Menu, Modal, ModalProps, Spin, message } from 'antd';

//import { TextInput } from 'components/form-control';
import { SearchIcon, XCircleIcon } from 'components/icons';
import { CustomPagination } from 'components/pagination';
import Table from 'components/table';
import { GetListCompanyParamsType } from 'services/api-external-user.type';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { renderSorterOrder } from 'components/table/table.const';
import { NoDataTable } from 'components/no-data-table';
import { useHistory, useLocation } from 'react-router-dom';
import { useParseParams } from 'hooks/use-params';
import { pushParamHandle } from 'modules/user-management/external-user/company/company.const';
import { UserCompanyType } from 'modules/user-management/external-user/company/company-user-modal/company-user-modal.const';
//import { isNil } from 'utils/helper';
import moment from 'moment';
import axiosClient from 'utils/axios-client';
import { TextInput } from 'components/form-control';
import { isNil } from 'utils/helper';

import styles from './style.module.scss';

import SortingArrow from 'components/sorting-arrow';
import { CommonButton } from 'components/common-button';
import { RowActionMenu, RowActionMenuProps } from 'modules/user-management/external-user/company/row-action-menu';
import dayjs from 'dayjs';
import ImportByTemplateModal from 'components/modal/import-by-template-modal';
import { ImportRolePermissionDto } from './role-permission.const';
import { arrayToCsv } from '../roles/tools';
import { AddRelationModal, AddRelationModalProps, IAddValues, IPageQuery, ValueItem } from '../add-relation-modal';
import { externalUserApi } from 'services/api-external-user.services';
import { regRoleName } from '../roles/roles.const';
import { regPermissionName } from '../permissions/permissions.const';

async function verifyImportData(rows: string[][], dtos: ImportRolePermissionDto[] | null = null, role_name: string = '', permission_name: string = '') {
  // console.log(rows[0]);
  var roleIdx = rows[0].indexOf('role_name');
  if (roleIdx < 0 || rows[0][0] !== 'table' || rows[0][roleIdx + 1] !== 'permission_name') {
    return { result: false, message: 'header fail, ' + rows[0] };
  }

  if (rows.length < 2) {
    return { result: false, message: 'empty data' };
  }

  var cnt = 0;

  for (var i = 1; i < rows.length; i++) {
    //console.log(rows);
    var row = rows[i];

    if (row[0] !== 'role_permission') continue;

    if (!row[roleIdx].match(regRoleName) || !row[roleIdx + 1].match(regPermissionName)) {
      //console.log(row);
      return { result: false, line: i + 1, message: 'key name fail: ' + row };
    }

    if (role_name && row[roleIdx] !== role_name) continue;
    if (permission_name && row[roleIdx + 1] !== permission_name) continue;

    if (dtos) {
      dtos.push({ table: row[0], role_name: row[roleIdx], permission_name: row[roleIdx + 1] });
    }

    cnt++;
  }

  if (cnt < 1) {
    return {
      result: false,
      message: 'empty data for table \'role_permission\''
        + (role_name ? (', role_name \'' + role_name + '\'') : '')
        + (permission_name ? (', permission_name \'' + permission_name + '\'') : ''),
    };
  }

  return { result: true };
}

async function importData(rows: string[][], role_name: string = '', permission_name: string = '') {
  var dtos: ImportRolePermissionDto[] = [];

  var r = await verifyImportData(rows, dtos, role_name, permission_name);
  if (!r.result)
    return r;

  // console.log(dtos);
  try {
    var res = await axiosClient.post('/role-permission/import', { list: dtos.filter(row => row.table === 'role_permission').map((v) => ({ ...v, table: undefined })) });
    if (res?.data.success) {
      return { result: true, message: 'Import success, add ' + res.data.add + ', skip ' + res.data.skip + '.' };
    }
    else {
      return { result: false, message: JSON.stringify(res).slice(0, 255) };
    }
  }
  catch (ex: any) {
    //console.log(ex);
    return { result: false, message: (ex?.response?.data?.message || ('' + ex)) };
  }
}

const ACTION_LABEL = {
  // EDIT: 'Edit',
  DELETE: 'Delete',
};

function ActionMenu({ row, className, action, ...props }: RowActionMenuProps) {
  const menu = (
    <Menu className={className}>
      <Menu.Item key='delte role-permission' onClick={() => { action(ACTION_LABEL.DELETE, row); }}>Delete</Menu.Item>
    </Menu>
  );
  return <RowActionMenu {...{ row, className, action, ...props, menu }} />;
}

export interface RolePermissionModalProps extends ModalProps {
  modalKey?: string;
  setModalVisible?: Dispatch<SetStateAction<boolean>> | undefined;
  title?: string;
  role_id?: number;
  role_name?: string;
  permission_id?: number;
  permission_name?: string;
}

export default function RolePermission(modalProps: RolePermissionModalProps | undefined = undefined) {
  const history = useHistory();
  const location = useLocation();

  // console.log('modalProps', modalProps, new Date());

  const [pageParam, setPageParam] = useState<any>({});

  useMemo(
    () => { setPageParam({ role_id: modalProps?.role_id, permission_id: modalProps?.permission_id }); },
    [modalProps],
  );

  const urlParam = useParseParams();
  const { pageSize, page, search, sortOrder, sortBy, tabKey, action } = modalProps?.modalKey ? pageParam : urlParam;
  const sortable = urlParam.sortable;

  const [valueSearch, setValueSearch] = useState<string>(search);

  const [isAdding, setIsAdding] = useState(false);
  const [isOpenImportPopup, setOpenImportPopup] = useState<boolean>(false);

  var setParamHandle = useCallback(
    (data: GetListCompanyParamsType) => {
      pushParamHandle(
        location.pathname,
        {
          page: page ?? 1,
          pageSize: pageSize ?? 50,
          search,
          sortOrder: sortOrder ?? 'desc',
          sortBy: sortBy ?? 'id',
          sortable: sortable || undefined,
          ...data,
        },
        history,
      );
    },
    [history, location.pathname, page, pageSize, search, sortBy, sortOrder, sortable],
  );

  const fetchListName1 = (param: IPageQuery) => {
    return externalUserApi.getListRolesWithRelation({
      page: param.page,
      pageSize: param.pageSize,
      search: param.search,
      sortBy: 'id',
      sortOrder: 'desc',
    });
  };
  const fetchListName2 = (param: IPageQuery) => {
    return externalUserApi.getListPermissionsWithRelation({
      page: param.page,
      pageSize: param.pageSize,
      search: param.search,
      sortBy: 'id',
      sortOrder: 'desc',
    });
  };

  const [addRelationArgs, setAddRelationArgs] = useState<AddRelationModalProps>({
    modalKey: 'add-role-permission',
    setModalVisible: setIsAdding,
    title: '',
    values: undefined,
    onModalOK: undefined,
    fetchListName1: fetchListName1,
    fetchListName2: fetchListName2,
  });

  const getRolePermissionList = (params: any) => {
    return axiosClient.get('/role-permission', {
      params: {
        ...params,
        sortOrder: params.sortOrder || 'desc',
        sortBy: params.sortBy || 'id',
        pageSize: params?.pageSize ?? 50,
        role_id: modalProps?.role_id || undefined,
        permission_id: modalProps?.permission_id || undefined,
      },
    }).then((res) => {
      if (res.data) {
        return res.data;
      } else {
        return {};
      }
    });
  };

  //console.log([location.pathname, pageSize, page, search, sortOrder, sortBy, roleIds, tabKey]);

  const {
    data: listRolePermissionData,
    isLoading: isListRolePermissionLoading,
    refetch: refetchList,
  } = useQuery(
    [
      'getListRolePermission',
      {
        pageSize,
        page,
        //status,
        search,
        sortBy,
        sortOrder,
        action,
      },
    ], () => {
      // console.log('search', search);
      return getRolePermissionList({
        pageSize,
        page,
        status: undefined,  //urlStatus !== 'active,disabled' ? urlStatus : undefined,
        search: search?.trim(),
        sortBy,
        sortOrder,
        action,
      });
    });

  const setPageParamHandle = useCallback(
    (data: GetListCompanyParamsType) => {
      setPageParam({ ...pageParam, ...data });
      refetchList();
    },
    [pageParam, refetchList],
  );

  if (modalProps?.modalKey) setParamHandle = setPageParamHandle;

  const onChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserCompanyType>[] | SorterResult<any>,
  ) => {
    setParamHandle({
      sortBy: (sorter as SorterResult<UserCompanyType>).field as string,
      sortOrder: renderSorterOrder((sorter as SorterResult<UserCompanyType>).order as string),
      tabKey,
    });
  };

  const renderTitle = (title: string, sortingFor?: string, tooltip?: string) => (
    <div className="renderTitle" key={title}>
      <div className="title">{title}</div>
      <SortingArrow order={sortOrder} orderBy={sortBy} sortingFor={sortingFor} />
    </div>
  );

  const columns: ColumnsType<UserCompanyType> = [
    {
      align: 'left',
      title: renderTitle('ID', 'id'),
      dataIndex: 'id',
      key: 'id',
      width: 80,
      sorter: true,
      //ellipsis: true,
    },
    {
      align: 'left',
      title: 'Role ID',
      dataIndex: 'role_id',
      key: 'role_id',
      width: 80,
      ellipsis: true,
      sorter: sortable,
    },
    {
      align: 'left',
      title: renderTitle('Role', 'role.name'),
      dataIndex: 'role.name',
      key: 'role.name',
      width: 160,
      // ellipsis: true,
      sorter: true,
      render: (_, row: any) => {
        return row.role.name;
      },
    },
    {
      align: 'left',
      title: 'Permission ID',
      dataIndex: 'permission_id',
      key: 'permission_id',
      width: 90,
      // ellipsis: true,
      sorter: sortable,
    },
    {
      align: 'left',
      title: renderTitle('Permission', 'permission.name'),
      dataIndex: 'permission.name',
      key: 'permission.name',
      width: 160,
      // ellipsis: true,
      sorter: true,
      render: (_, row: any) => {
        return row.permission.name;
      },
    },
    {
      align: 'left',
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 150,
      sorter: sortable,
      ellipsis: true,
      render: (_, data: any) => {
        return (
          <div>{isNil(data.created_at) ? '-' : moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
        );
      },
    },
    // {
    //   align: 'left',
    //   title: 'Updated At',
    //   dataIndex: 'updated_at',
    //   key: 'updated_at',
    //   width: 150,
    //   //sorter: true,
    //   ellipsis: true,
    //   render: (_, data: any) => {
    //     return (
    //       <div>{isNil(data.updated_at) ? '-' : moment(data.updated_at).format('YYYY-MM-DD HH:mm:ss')}</div>
    //     );
    //   },
    // },
    {
      title: 'Action',
      dataIndex: 'rolePermissionAction',
      key: 'rolePermissionAction',
      width: 80,
      align: 'center',
      fixed: 'right',

      render: (_, data: UserCompanyType) => {
        return (
          <ActionMenu row={data} className={'actionMenu'} action={onRolePemissionAction} />
        );
      },

    },
  ];

  const ClearFilters = () => {
    setValueSearch('');
    setParamHandle({ page: 1, pageSize: 50, search: undefined, tabKey });
  };

  const onChangePage = (page: number, pageSize: number) => {
    setParamHandle({ page, pageSize, tabKey, action });
  };

  const totalResult = useMemo(() => {
    return listRolePermissionData?.total ?? 0;
  }, [listRolePermissionData?.total]);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueSearch(e.target.value);
    setTimeout(() => {
      setParamHandle({ tabKey, search: e.target.value, page: 1 });
    }, 700);
  };

  const onAddRolePermissionOk = async (values: IAddValues, valueList?: ValueItem[]) => {
    if (valueList) {
      // multi-select
      // console.log(values, valueList);
      const submitList = values.name1
        ? valueList.map((v) => ({ role_name: values.name1, permission_name: v.label }))
        : valueList.map((v) => ({ role_name: v.label, permission_name: values.name2 }));

      try {
        var res = await axiosClient.post('/role-permission/import', { list: submitList });
        if (res?.data.success) {
          message.success('Success, add ' + res.data.add + ', skip ' + res.data.skip + '.');
          refetchList();
        }
        else {
          message.error(JSON.stringify(res).slice(0, 255));
        }
      }
      catch (ex: any) {
        //console.log(ex);
        message.error(ex?.response?.data?.message || ('' + ex));
      }
      return;
    }

    // console.log(values);
    axiosClient.post('/role-permission', {
      role_name: values.name1,
      permission_name: values.name2,
    }).then((res) => {
      // console.log(res);
      message.success('Success to create a role-permission');
      refetchList();
    }).catch((ex) => {
      // console.log(ex);
      message.error('Error: ' + (ex?.response?.data?.message || ex));
    });
  };

  const onAddRolePermission = () => {
    setAddRelationArgs({
      ...addRelationArgs,
      title: 'Add Role-Permission Relation',
      values: {
        id1: modalProps?.role_id || 0,
        name1: modalProps?.role_name || '',
        id2: modalProps?.permission_id || 0,
        name2: modalProps?.permission_name || '',
      },
      onModalOK: onAddRolePermissionOk,
    });
    setIsAdding(true);
    // console.log('open add dlg');
  };

  const handleDeleteRolePermission = (row: any) => {
    var valueCheck = row.role.name + ' + ' + row.permission.name;
    var value: any = '';
    var modal = Modal.confirm({
      title: 'Confirm Deleting',
      content: <p>To confirm the deleting of role-permission <span style={{ color: 'green' }}>{valueCheck}</span>,
        please type or paste the role-permission's name below.<br />
        <br />
        The role-permission &nbsp;
        <input onChange={(evt) => {
          value = evt.target.value.trim();
          modal.update({ okButtonProps: { disabled: value !== valueCheck && value !== 'skip for batch' } });
        }} />
        &nbsp; will be deleted.
      </p>,
      width: 600,
      okButtonProps: { disabled: true },
      closable: true,
      maskClosable: true,
      onOk: () => {
        // console.log(value);
        axiosClient.delete('/role-permission/' + row.id).then((res) => {
          // console.log(res);
          message.success('Success to delete a role-permission');
          refetchList();
        }).catch((ex) => {
          // console.log(ex);
          message.error('Error: ' + (ex?.response?.data?.message || ex));
        });

      },
    });

  };

  async function onRolePemissionAction(label: string, row: any) {
    // if (label === ACTION_LABEL.EDIT) handleEditRoles(row);
    // else 
    if (label === ACTION_LABEL.DELETE) handleDeleteRolePermission(row);
  }

  const onExportRolePermission = () => {
    const num = listRolePermissionData.data.length || 0;
    if (!(num > 0)) {
      message.info('no role to export in current page.');
      return;
    }

    Modal.confirm({
      title: 'Export Confirmation',
      content: <p>
        Please confirm to export the <b style={{ color: 'green' }}>{num}</b> role-permission{(num > 1) ? 's' : ''}  in current page?<br />
      </p>,
      closable: true,
      maskClosable: true,
      onOk: async () => {
        var exData = [
          ['table', 'role_name', 'permission_name'],
        ];
        listRolePermissionData.data.forEach((row: any) => {
          exData.push(['role_permission', row.role.name, row.permission.name]);
        });

        //sort
        exData = [
          exData[0],
          ...exData.slice(1).sort((a, b) => {
            return a[1].localeCompare(b[1], undefined, { sensitivity: 'base' }) || a[2].localeCompare(b[2], undefined, { sensitivity: 'base' });
          }),
        ];

        const csvContent =
          'data:text/csv;charset=utf-8,' + arrayToCsv(exData);
        const encodedUri = encodeURI(csvContent);

        const link = document.createElement('a');
        link.href = encodedUri;
        link.download = 'role_permission-' + dayjs().format('YYYYMMDD-HHmmss') + '.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    });
  };

  return (
    <>
      <div key={'RolePermissionPanel' + tabKey}>
        <p className="filter-hub-text filter-hub-top-text">Filter Role-Permission</p>
        <div className="filter-hub-flex">
          <div className="filter-hub-options">
            <TextInput
              placeholder="Search names"
              className="input"
              inputProps={{
                onChange: onChangeInput,
                value: modalProps?.modalKey ? `"${modalProps.role_name || modalProps.permission_name}"` : valueSearch,
                prefix: <SearchIcon width={20} height={20} />,
                maxLength: 255,
              }}
              disabled={!!modalProps?.modalKey}
            />
          </div>
          <div className="filter-hub-btns" style={{ display: 'flex' }}>
            <div className="clear-filters" onClick={ClearFilters}>
              <span>Clear all filters</span>
              <XCircleIcon width={24} height={24} />
            </div>
            <CommonButton variant="link"
              key="add-role-permission"
              onClick={onAddRolePermission}
              disabled={false}
              style={{ marginLeft: '2em' }}
            >
              Add
            </CommonButton>
            <CommonButton variant="link"
              key="export-role-permission"
              onClick={onExportRolePermission}
              disabled={false}
              style={{ marginLeft: '0.5em' }}
            >
              Export
            </CommonButton>
            <CommonButton variant="link"
              key="import-role-permission"
              onClick={() => { setOpenImportPopup(true); }}
              disabled={false}
              style={{ marginLeft: '0.5em' }}
            >
              Import
            </CommonButton>
          </div>
        </div>
        {isListRolePermissionLoading ? (
          <Spin className="spin" />
        ) : (
          <>
            {!listRolePermissionData?.data || listRolePermissionData?.data.length === 0 ? (
              <NoDataTable />
            ) : (
              <>
                <Table
                  rowKey={'id'}
                  columns={columns}
                  dataSource={listRolePermissionData?.data || []}
                  pagination={false}
                  onChange={onChangeTable}
                  loading={isListRolePermissionLoading}
                />

                <div className="pagination">
                  <div className="total-results-text">
                    Total results: <p className="total-results-number">{totalResult}</p>
                  </div>
                  <CustomPagination
                    pageSize={listRolePermissionData?.pageSize ?? 50}
                    current={listRolePermissionData?.page ?? 1}
                    total={totalResult}
                    onChange={onChangePage}
                    showSizeChanger={true}
                  />
                </div>
              </>
            )}
          </>
        )}

      </div>

      {isAdding && (
        <AddRelationModal
          open={true}
          // onCancel={() => { setIsItemEditing(false); }}
          // setModalVisible={setIsAdding}
          {...addRelationArgs}
        />
      )}

      {isOpenImportPopup && (
        <ImportByTemplateModal
          title={'Setting - Import Role-Permission'}
          verifyData={async (rows: string[][]) => await verifyImportData(rows, null, modalProps?.role_name || '', modalProps?.permission_name || '')}
          closePopup={() => { setOpenImportPopup(false); }}
          importData={async (rows: string[][]) => {
            var ret = await importData(rows, modalProps?.role_name || '', modalProps?.permission_name || '');
            await refetchList();
            return ret;
          }}
          action='upload-import-role-permission'
          templateName='setting-import-role-permission-template'
          xlsxTemplateUrl='/template/setting-import-role-permission-template.xlsx'
          csvTemplateUrl='/template/setting-import-role-permission-template.csv'
        />
      )}

    </>
  );
}

export function RolePermissionModal(props: RolePermissionModalProps) {
  return (
    <div >
      <Modal
        {...props}
        // onCancel={onCancel}
        centered
        title={props?.title || 'Role-Permission'}
        forceRender
        className={styles.rolePermissionModal}
        // style={{ width: '90%' }}
        width={'95%'}
        closable={true}
        maskClosable={true}
        footer={''}
        onCancel={() => { props.setModalVisible?.(false); }}
      >
        <RolePermission
          {...props}
        />
      </Modal>
    </div>
  );
}
