import { Col, Divider, Form, message, ModalProps, Row, Steps } from 'antd';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import ModalBase from '../modal-base';
import {
  CompanyInfoType,
  errorKeyWordNewCompany,
  RenderTypeOfStepType,
  SHOULD_CREATE_COMPANY_ADMIN_BY_EMAIL,
  StepsType,
} from './create-new-company-modal.type';

import styles from './create-new-company-modal.module.scss';
import stylesChangPlan from '../../../modules/user-management/external-user/company/change-plan-modal/change-plan-modal.module.scss';


import LabelRequire from 'components/label-require';

import { ArrowRightIcon, InfoCircleFilledIcon } from 'components/icons';
import { TextInput } from 'components/form-control';
import { CommonButton } from 'components/common-button';
import { externalUserApi } from 'services/api-external-user.services';

import { isEmpty } from 'utils/helper';
import { emailRegex } from 'utils/regex';
import SuccessPopup from '../success-popup';
import ChangePlanForm from 'modules/user-management/external-user/company/change-plan-modal/change-plan-form';
import { roleDefault } from 'modules/user-management/external-user/company/change-plan-modal/role-tool';
import clsx from 'clsx';
import {
  KioskTableData,
  loadCompanyKioskTable,
} from 'modules/user-management/external-user/company/change-plan-modal/kiosk-data';
import SwitchStandalone from '../../switch-standalone';

const { Step } = Steps;

interface CreateNewCompanyModalProps extends ModalProps {
  togglePopup: () => void;
  refetch: () => void;
}

const CreateNewCompanyModal = ({ togglePopup, refetch, ...props }: CreateNewCompanyModalProps) => {
  //console.log([togglePopup,refetch,props]);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [companyInfo, setCompanyInfo] = useState<CompanyInfoType>({
    companyName: '',
    companyDomain: '',
    companyAddress: '',
    firstName: '',
    lastName: '',
    emailName: '',
    phoneNumber: '',
    userName: '',
    shouldCreateCompanyAdminByEmail: false,
  });

  const [isOpenSuccessPopup, setOpenSuccessPopup] = useState<boolean>(false);

  const companyDetails = useMemo(
    () => {
      return {
        id: '',
        name: companyInfo.companyName,
        domain: companyInfo.companyDomain,
        //plan_start_date: dayjs().format('YYYY-MM-DD'),
        //plan_end_date: dayjs(new Date()).add(1, 'd').format('YYYY-MM-DD'),
        start_date: '',
        expired_date: '',
        plan_start_date: '',
        plan_end_date: '',

        is_new_member_need_audit: false,
        //is_accept_public_email: false,
        //is_accept_prohibited_email: false,
        is_accept_public_email: true,   //default open
        is_accept_prohibited_email: true,   //default open
        company_key: '',
        invitation_code: '',

        report_quota: 10,
        number_of_license: 10,
        number_of_disclosure: 0,
        number_of_disclosure_holding: 0,
        blueOnionHubRole: {},

        ...roleDefault,
      };
    },
    //[companyInfo, configurePlanInfo],
    [companyInfo.companyName, companyInfo.companyDomain],
  );
  //console.log('companyDetails', companyDetails);
  //console.log(dayjs(new Date()).add(2, 'd').format('YYYY-MM-DD'));

  //kisok data

  //setKioskTableData(null);
  const { data: queryKioskTableData } = useQuery(['loadKioskTableForNewCompany'], (): Promise<KioskTableData> => {
    return loadCompanyKioskTable(0, kioskTableData);
  });
  //console.log('queryKioskTableData',queryKioskTableData);

  const [kioskTableData, setKioskTableData] = useState<KioskTableData | undefined>(queryKioskTableData);

  function onKioskUpdateData(newKioskTableData: KioskTableData) {
    //console.log('newKioskTableData',newKioskTableData);
    setKioskTableData(newKioskTableData);
  }

  //console.log('kioskTableData', kioskTableData);
  if (!kioskTableData && queryKioskTableData) setKioskTableData(queryKioskTableData);

  const fetchListRoleCompany = () => externalUserApi.getListRoleCompany();
  const { data: listRoleCompanyData } = useQuery(
    ['listRoleCompanyAddNewUserExternal'],
    fetchListRoleCompany,
  );

  const fetchCreateCompany = async (detail: any) => {
    //console.log('detail', detail);

    const infoCompany = {
      name: companyInfo.companyName,
      domain: companyInfo.companyDomain,
      address: companyInfo.companyAddress,
      contact_first_name: companyInfo.firstName,
      contact_last_name: companyInfo.lastName,
      contact_email: companyInfo.emailName,
      contact_phone: companyInfo.phoneNumber,
      plan_start_date: detail.plan_start_date,
      plan_end_date: detail.plan_end_date,
      role_ids: detail.role_ids,
      report_quota: Number(detail.report_quota),
      number_of_license: Number(detail.report_quota),
      number_of_disclosure: Number(detail.number_of_disclosure) || 0,
      number_of_disclosure_holding: Number(detail.number_of_disclosure_holding) || 0,
      should_create_company_admin_by_email: companyInfo.shouldCreateCompanyAdminByEmail,
      username: companyInfo.userName,
    };

    return await externalUserApi.createCompany(infoCompany);
  };

  const [form] = Form.useForm<{ [key: string]: string }>();

  const onChangeCompanyInfo = (companyInfoName: keyof CompanyInfoType, value: string) => {
    setCompanyInfo((prev) => {
      if (companyInfoName === SHOULD_CREATE_COMPANY_ADMIN_BY_EMAIL) {
        return {
          ...prev,
          [companyInfoName]: value === 'true' ? true : false,
        };
      }
      return {
        ...prev,
        [companyInfoName]: prev[companyInfoName] === value.trim() ? prev[companyInfoName] : value.trim(),
      };
    });
  };

  const MAX_STEP = 2;

  const handleClickSuccessPopup = () => {
    setOpenSuccessPopup(false);
    togglePopup();
  };


  const onBack = () => {
    //console.log('onBack', onBack);
    if (currentStep <= 0) return;
    setCurrentStep((prev) => prev - 1);
  };


  const onNext = () => {
    if (currentStep >= MAX_STEP - 1) return;
    setCurrentStep((prev) => prev + 1);
  };

  const onSave = async (detail: any) => {

    try {
      let ret = await fetchCreateCompany(detail);
      //console.log('ret', ret);
      await refetch();
      setOpenSuccessPopup(true);
      return ret;
    } catch (err: any) {
      //console.log('ex', err);

      setCurrentStep((prev) => prev - 1);
      const messageError = err?.response?.data?.message;
      const unprocessableEntityError = Object.values(err?.response?.data?.errors?.[0]?.constraints || {})?.[0] as
        | string
        | undefined;

      if (isEmpty(err?.response)) {
        message.error({
          content: 'Please check your internet connection and try again.',
          className: 'custom-class',
          style: {
            textAlign: 'center',
          },
        });
        return;
      }
      if (messageError.includes(errorKeyWordNewCompany.domainExisted)) {
        form.setFields([
          { name: 'companyDomain', errors: ['This email domain already exists. Please input a new email domain'] },
        ]);
        return;
      }
      if (messageError.includes(errorKeyWordNewCompany.emailExisted)) {
        form.setFields([{ name: 'emailName', errors: ['This email already exists. Please input a new email'] }]);
        return;
      }
      if (messageError.includes(errorKeyWordNewCompany.phoneExisted)) {
        form.setFields([
          { name: 'phoneNumber', errors: ['This phone number already exists. Please input a different phone number'] },
        ]);
        return;
      }
      if (unprocessableEntityError?.includes('phone')) {
        form.setFields([{ name: 'phoneNumber', errors: ['Phone format is not valid. Please check again'] }]);
        return;
      }
      if (unprocessableEntityError?.includes('domain')) {
        form.setFields([{ name: 'companyDomain', errors: ['Domain must be an URL address'] }]);
        return;
      }
      message.error({
        content: err?.response?.data?.message,
        className: 'custom-class',
        style: {
          textAlign: 'center',
        },
      });
    }
  };

  const steps: StepsType[] = [
    {
      key: 1,
      title: 'Enter Company Information',
      content: (
        <Form form={form}>
          <div className="notice-info">
            <InfoCircleFilledIcon />
            <div className="notice-text">
              Create new company need accurate email domain. Please enter the right information
            </div>
          </div>
          <Row gutter={[0, 20]} className="mt-5">
            <Col span={24}>
              <LabelRequire text="Company Name" require className="pb-1" />
              <TextInput
                placeholder="Input Company name"
                name="companyName"
                inputProps={{ autoFocus: true, onChange: (e) => onChangeCompanyInfo('companyName', e.target.value) }}
                rules={[
                  { max: 255, message: 'You have reach the maximum character for this field: 255 character' },
                  {
                    validator(_, value) {
                      if (value.trim()) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject('This field is mandatory');
                      }
                    },
                  },
                ]}
              />
            </Col>
            <Col span={24}>
              <LabelRequire text="Company Email Domain" require className="pb-1" />
              <TextInput
                placeholder="Example: company.com"
                name="companyDomain"
                inputProps={{ onChange: (e) => onChangeCompanyInfo('companyDomain', e.target.value) }}
                rules={[
                  { required: true, message: 'This field is mandatory' },
                  {
                    pattern: new RegExp(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g),
                    message:
                      'You can only input alphabet character, number, hyphen (-) and dot (.) for company email domain',
                  },
                  { max: 255, message: 'You have reach the maximum character for this field: 255 character' },
                ]}
              />
            </Col>
            <Col span={24}>
              <LabelRequire text="Company Office Address" className="pb-1" />
              <TextInput
                placeholder="Input Company office address"
                name="companyAddress"
                inputProps={{ onChange: (e) => onChangeCompanyInfo('companyAddress', e.target.value) }}
                rules={[{ max: 255, message: 'You have reach the maximum character for this field: 255 character' }]}
              />
            </Col>
          </Row>
          <LabelRequire text="Primary Contact" require className="pb-3 mt-5" />
          <div className="plan-includes">
            <Row gutter={[20, 20]}>
              <Col span={12}>
                <LabelRequire text="First Name" className="pb-1" />
                <TextInput
                  placeholder="Input First name"
                  name="firstName"
                  inputProps={{ onChange: (e) => onChangeCompanyInfo('firstName', e.target.value) }}
                  rules={[
                    {
                      validator(_, value) {
                        if (value.trim()) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject('This field is mandatory');
                        }
                      },
                    },
                    { max: 100, message: 'You have reach the maximum character for this field: 100 characters' },
                  ]}
                />
              </Col>
              <Col span={12}>
                <LabelRequire text="Last Name" className="pb-1" />
                <TextInput
                  placeholder="Input Last name"
                  name="lastName"
                  inputProps={{ onChange: (e) => onChangeCompanyInfo('lastName', e.target.value) }}
                  rules={[
                    {
                      validator(_, value) {
                        if (value.trim()) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject('This field is mandatory');
                        }
                      },
                    },
                    { max: 100, message: 'You have reach the maximum character for this field: 100 characters' },
                  ]}
                />
              </Col>
              <Col span={12}>
                <LabelRequire text="Email" className="pb-1" />
                <TextInput
                  placeholder="Input Email"
                  name="emailName"
                  inputProps={{ onChange: (e) => onChangeCompanyInfo('emailName', e.target.value) }}
                  rules={[
                    { required: true, message: 'This field is mandatory' },
                    { max: 100, message: 'The maximum length for User Name is: 100 characters' },
                    {
                      pattern: new RegExp(emailRegex),
                      message: 'Email format is not valid. Please check again',
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <LabelRequire text="Phone Number" className="pb-1" />
                <TextInput
                  placeholder="Input Phone number"
                  name="phoneNumber"
                  inputProps={{
                    onChange: (e) => onChangeCompanyInfo('phoneNumber', e.target.value),
                  }}
                  rules={[
                    {
                      max: 15,
                      message: 'You have reach the maximum character for Phone Number: 15 characters',
                    },
                    {
                      pattern: new RegExp(/^[+]*[(]{0,1}[+]{0,1}[0-9]{1,}[)]{0,1}[0-9]*$/g),
                      message: 'This field only allow input number and these special character: ()+',
                    },
                    { required: true, message: 'This field is mandatory' },
                  ]}
                />
              </Col>
              <Col span={12}>
                <LabelRequire text="Create Admin" className="pb-1" />
                <SwitchStandalone
                  text=""
                  disabled={!true}
                  defaultChecked={false}
                  onClick={(boolValue, _) => onChangeCompanyInfo(SHOULD_CREATE_COMPANY_ADMIN_BY_EMAIL, `${boolValue}`)}
                />
              </Col>
              <Col span={12}>
                <LabelRequire text="User Name" className="pb-1" />
                <TextInput
                  placeholder="Input User Name"
                  name="userName"
                  disabled={!companyInfo.shouldCreateCompanyAdminByEmail}
                  inputProps={{
                    onChange: (e) => onChangeCompanyInfo('userName', e.target.value),
                  }}
                  rules={[
                    {
                      max: 100,
                      message: 'You have reach the maximum character for Username: 100 characters',
                    },
                  ]}
                />
              </Col>
            </Row>
          </div>
          <div className="w-100 footer">
            <Form.Item shouldUpdate>
              {({ getFieldsError }) => {
                const isDisabled =
                  !companyInfo.companyName ||
                  !companyInfo.companyDomain ||
                  !companyInfo.firstName ||
                  !companyInfo.lastName ||
                  !companyInfo.emailName ||
                  !companyInfo.phoneNumber ||
                  !!getFieldsError().filter(({ errors }) => errors.length).length;
                return (
                  <CommonButton
                    variant="primary"
                    htmlType="submit"
                    onClick={onNext}
                    className="ml-auto"
                    reverseIcon
                    icon={<ArrowRightIcon className="ml-2" />}
                    disabled={isDisabled}
                    idKey='CreateCompanyPlan'
                  >
                    Configure Plan
                  </CommonButton>
                );
              }}
            </Form.Item>
          </div>
        </Form>
      ),
    },
    {
      key: 2,
      title: 'Configure Plan',
      content: (
        <>
          <ChangePlanForm
            setVisible={() => { }}
            companyDetails={companyDetails}

            listCompanyRoles={listRoleCompanyData}

            kioskTableData={kioskTableData}

            onKioskUpdateData={onKioskUpdateData}

            onStepBack={onBack}
            onStepSave={onSave}

            onUpdateData={async () => { return await refetch(); }}
          />
        </>
      ),
    },
  ];

  const renderTypeOfStep: RenderTypeOfStepType = (indexStep) => {
    const typeOfStep = {
      previous: { titleClassName: 'text-previous-step', iconClassName: 'previous-step' },
      current: { titleClassName: 'text-current-step', iconClassName: 'current-step' },
      next: { titleClassName: 'text-next-step', iconClassName: 'next-step' },
    };
    if (indexStep < currentStep) return typeOfStep.previous;
    if (indexStep === currentStep) return typeOfStep.current;
    return typeOfStep.next;
  };

  return (
    <ModalBase
      {...props}
      title="Create New Company"
      visible={true}
      centered
      className={clsx([styles.mainContent, stylesChangPlan.changePlanModal])}
      onCancel={togglePopup}
    >
      <Steps current={currentStep} size="small">
        {steps.map(({ key, title }, index) => {
          const { iconClassName, titleClassName } = renderTypeOfStep(index);
          return (
            <Step
              key={title}
              title={<div className={titleClassName}>{title}</div>}
              icon={<div className={iconClassName}>{key}</div>}
            />
          );
        })}
      </Steps>
      <Divider />

      <StepsContent current={currentStep} steps={steps} />

      {isOpenSuccessPopup && (
        <SuccessPopup
          togglePopup={handleClickSuccessPopup}
          content={
            <div>
              New Company - <span className="whitespace-pre-wrap">{companyInfo.companyName}</span> has been created
              successfully!
            </div>
          }
        />
      )}
    </ModalBase>
  );
};

interface StepsContentProps {
  steps: StepsType[];
  current: number;
}

const StepsContent = ({ current, steps }: StepsContentProps) => {
  const content = steps.map((v, idx) => (
    <div key={'createCompany' + idx} className="steps-content" style={{ display: (idx === current ? '' : 'none') }}>{v.content}</div>
  ));
  return <>{content}</>;
};

export default CreateNewCompanyModal;

