import { Dropdown, Menu } from 'antd';

export type RowActionMenuProps = {
  row: any; //a data row
  className?: string;
  action: (label: string, row?: any) => any;
  menu?: JSX.Element;
  actionLabels?: any; //if menu is empty, actionLabels should be provided.
  width?: number;    //popup menu width
};

//NOTE: this is for antd-^4.16.13 - 4.23.6, not for 4.24.0
//!!! be carefule update to 4.24.0 until preparing well
export function RowActionMenu({ row, className, action, menu, actionLabels, ...props }: RowActionMenuProps) {
  if (!menu) {
    const transferMenu = (labelsObject: any) => {
      //console.log(row.id);
      let memuItems: JSX.Element[] = [];
      Object.keys(labelsObject).forEach((v, idx) => {
        if (labelsObject[v] === '-') {
          memuItems.push(<Menu.Divider key={'Divider' + v + row.id} />);
        }
        else if (typeof labelsObject[v] === 'object') {
          memuItems.push(
            (
              <Menu.SubMenu
                popupClassName={'actionMenuSub'}
                key={'menuitem' + v + row.id}
                title={labelsObject[v]['']}
                children={transferMenu(labelsObject[v])}
              />
            ),
          );
        }
        else if (!v) {
        }
        else {
          memuItems.push((
            <Menu.Item
              key={'menuitem' + v + row.id}
              onClick={() => { action(labelsObject[v], row); }}
            >
              {labelsObject[v]}
            </Menu.Item>
          ));
        }
      });
      return memuItems;
    };

    let memuItems = transferMenu(actionLabels);

    menu = <Menu className={className} key={'menu' + row.id} {...props.width ? { style: { width: props.width } } : null} > {memuItems} </Menu>;
  }
  //console.log(className);
  return <>
    <Dropdown overlay={menu} key={'Dropdown' + row.id} trigger={['click']}>
      <span style={{ cursor: 'pointer' }} title='Actions'>. . .</span>
    </Dropdown>
  </>;
}
