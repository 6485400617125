// https://blog.csdn.net/weixin_34034261/article/details/92686022
export const regionSet: { [key: string]: Set<string> } = {
  'Africa': new Set(['ao', 'bf', 'bi', 'bj', 'bw', 'cd', 'cf', 'cg', 'ci', 'cm', 'cv', 'dj', 'dz', 'eg', 'eh', 'er', 'et', 'ga', 'gh', 'gm', 'gn', 'gq', 'gw', 'ke', 'km', 'lr', 'ls', 'ly', 'ma', 'mg', 'ml', 'mr', 'mu', 'mw', 'mz', 'na', 'ne', 'ng', 're', 'rw', 'sc', 'sd', 'sl', 'sn', 'so', 'st', 'sz', 'td', 'tg', 'tn', 'tz', 'ug', 'za', 'zm', 'zr', 'zw']),
  'Asia': new Set(['bd', 'bn', 'bt', 'cn', 'hk', 'id', 'in', 'jp', 'kh', 'kp', 'kr', 'la', 'lk', 'mm', 'mn', 'mo', 'my', 'np', 'ph', 'pk', 'sg', 'th', 'tw', 'vn', 'xa']),
  'Europe': new Set(['ad', 'al', 'am', 'at', 'az', 'ba', 'be', 'bg', 'by', 'ch', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fo', 'fr', 'fx', 'gb', 'ge', 'gg', 'gi', 'gr', 'hr', 'hu', 'ie', 'im', 'is', 'it', 'je', 'kg', 'kz', 'li', 'lt', 'lu', 'lv', 'mc', 'md', 'me', 'mf', 'mk', 'mt', 'nl', 'no', 'pl', 'pt', 'ro', 'rs', 'ru', 'se', 'si', 'sk', 'sm', 'tj', 'tm', 'ua', 'uz', 'va', 'ym', 'yu', 'xk']),
  'Middle America': new Set(['ag', 'ai', 'an', 'bb', 'bm', 'bs', 'bz', 'cr', 'ct', 'cu', 'dm', 'do', 'gd', 'gt', 'hn', 'ht', 'jm', 'kn', 'ky', 'lc', 'mq', 'ms', 'ni', 'pa', 'pr', 'pz', 'sv', 'tc', 'tf', 'tt', 'vc', 'vg', 'vi', 'xb']),
  'Middle East': new Set(['ae', 'af', 'ax', 'bh', 'cs', 'cy', 'il', 'iq', 'ir', 'jo', 'kw', 'lb', 'nt', 'om', 'po', 'ps', 'qa', 'sa', 'sy', 'tl', 'tr', 'ye']),
  'North America': new Set(['gl', 'ca', 'pm', 'bm', 'mx', 'gt', 'gp', 'hm', 'pm', 'sh', 'us']),
  'Oceania': new Set(['as', 'au', 'cc', 'ck', 'cx', 'fj', 'fm', 'gu', 'jt', 'ki', 'mh', 'mi', 'mp', 'mv', 'nc', 'nf', 'nr', 'nu', 'nz', 'pf', 'pg', 'pw', 'sb', 'tk', 'to', 'tp', 'tv', 'vu', 'wf', 'wk', 'ws', 'xc']),
  'Others': new Set(['aq', 'bq', 'bv', 'gs', 'io', 'pn', 'sj', 'um', 'yt', 'zz','xsn']),
  'South America': new Set(['ar', 'aw', 'bo', 'br', 'cl', 'co', 'ec', 'fk', 'gf', 'gy', 'pe', 'py', 'sr', 'uy', 've']),
  'Frequently used': new Set(['hk', 'tw', 'sg', 'gb', 'th', 'jp']),
};

export const regionOptions = [
  { value: '', label: '- None -', titel: 'None' },
  { value: 'Africa', label: 'Africa' },
  { value: 'Asia', label: 'Asia' },
  { value: 'Europe', label: 'Europe' },
  { value: 'Middle America', label: 'Middle America' },
  { value: 'Middle East', label: 'Middle East' },
  { value: 'North America', label: 'North America' },
  { value: 'Oceania', label: 'Oceania' },
  { value: 'South America', label: 'South America' },
  { value: 'Others', label: 'Others' },
  { value: 'Frequently used', label: 'Frequently used' },
];