import { Col, Form, Modal, ModalProps, Row } from 'antd';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { CommonButton } from 'components/common-button';
// import { TextInput } from 'components/form-control';
import LabelRequire from 'components/label-require';
// import { ModalBase } from 'components/modal';
import styles from './add-object-role-modal.module.scss';
import SelectSearch, { OptionsSelectType } from 'components/select-search/select-search';
import { useInfiniteQuery } from 'react-query';
import { useDebounce } from 'hooks/use-debounce';
import SwitchInput from 'components/switch';
import DateInput from 'components/form-control/date-input';
// import dayjs from 'dayjs';
import moment from 'moment';

export type IAddValues = {
  rowId?: number;
  role_id?: number;
  object_id?: number;
  is_allow?: boolean;
  start_date?: string;
  end_date?: string;
  object_type?: string;   //output

  role_name?: string;
  object_name?: string;
};

export type IPageQuery = {
  page: number,
  pageSize: number,
  search: string,
};

export interface AddObjectRoleModalProps extends ModalProps {
  modalKey: string;
  setModalVisible: Dispatch<SetStateAction<boolean>> | undefined;
  title?: string;
  values?: IAddValues | undefined;
  onModalOK?: (values: IAddValues, roleList?: ValueItem[], objectList?: ValueItem[]) => any;
  fetchListName1: (param: IPageQuery, objectType?: string, objectId?: number) => Promise<any>;
  fetchListName2: (param: IPageQuery) => Promise<any>;
  objectType?: string;
  objectTypeName?: string;
  nameField1?: string;
  nameField2?: string | string[];

  role_title?: string;    //default: 'Role'
  role_id_field?: string;   //default: 'role_id'
  // company_role_field?: string;   //default: 'companyRole'
}

const PAGE_SIZE = 20;

function isSameDate(d1: any, d2: any) {
  if (!d1) d1 = '';
  if (!d2) d2 = '';
  return d1 === d2;
}

export type ValueItem = {
  value: number;
  label: string;
};

export function AddObjectRoleModal({ setModalVisible, values, ...props }: AddObjectRoleModalProps) {
  // console.log('role_id,object_id', values?.role_id, values?.role_name, values?.object_id);
  const [valueRoleId, setValueRoleId] = useState<number>(values?.role_id || 0);
  const [valueObjectId, setValueObjectId] = useState<number>(values?.object_id || 0);
  const [searchName1, setSearchName1] = useState<string>('');
  const [searchName2, setSearchName2] = useState<string>('');
  const [allowValue, setAllowValue] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [form] = Form.useForm();

  const multipleRole = !!(values?.object_id && !values?.role_id);
  const [multipleRoleValue, setMultipleRoleValue] = useState<ValueItem[]>([]);
  const multipleObject = !!(values?.role_id && !values?.object_id);
  const [multipleObjectValue, setMultipleObjectValue] = useState<ValueItem[]>([]);

  var varName = props?.modalKey + '-add-o-r';

  useEffect(() => {
    // console.log('useEffect',values?.role_id );
    setValueRoleId(values?.role_id || 0);
    setValueObjectId(values?.object_id || 0);
    setSearchName1('');
    setSearchName2('');
    setAllowValue(values?.is_allow || false);
    // setStartDate(values?.start_date ? dayjs(values?.start_date).format('DD/MM/YYYY') : '');
    // setEndDate(values?.end_date ? dayjs(values?.end_date).format('DD/MM/YYYY') : '');
    setStartDate(values?.start_date || '');
    setEndDate(values?.end_date || '');

    let startMmt = values?.start_date ? moment(values?.start_date, 'YYYY-MM-DD') : undefined;
    form.setFieldValue(varName + '_start_date', startMmt);
    let endMmt = values?.end_date ? moment(values?.end_date, 'YYYY-MM-DD') : undefined;
    form.setFieldValue(varName + '_end_date', endMmt);
  }, [
    values, form, varName, values?.role_id, values?.object_id,
  ]);

  // console.log(startDate, endDate);
  // var startMmt = startDate ? moment(startDate, 'YYYY-MM-DD') : undefined;
  // console.log(startMmt, startMmt?.isValid());

  const debounceValueName1Search = useDebounce<string>(searchName1.trim());
  const debounceValueName2Search = useDebounce<string>(searchName2.trim());

  const fetchListName1Callback = useCallback(
    ({ pageParam = 1 }) => {
      if ((values?.object_type || props.objectType) && (values?.object_id || valueObjectId) && !values?.role_id) {
        // return props.fetchListName1({ page: pageParam, pageSize: PAGE_SIZE, search: debounceValueName1Search }, values?.object_type, values?.object_id);
        return props.fetchListName1({ page: pageParam, pageSize: PAGE_SIZE, search: debounceValueName1Search }, values?.object_type || props.objectType, values?.object_id || valueObjectId);
      }
      else {
        return props.fetchListName1({ page: pageParam, pageSize: PAGE_SIZE, search: debounceValueName1Search });
      }
    },
    [debounceValueName1Search, props, valueObjectId, values],
  );

  const fetchListName2Callback = useCallback(
    ({ pageParam = 1 }) => {
      return props.fetchListName2({ page: pageParam, pageSize: PAGE_SIZE, search: debounceValueName2Search });
    },
    [debounceValueName2Search, props],
  );

  const {
    data: listName1Data,
    isLoading: isListName1Loading,
    fetchNextPage: name1FetchNextPage,
    hasNextPage: name1HasNextPage,
    refetch: refetchName1,
  } = useInfiniteQuery(['listName1Data' + props.modalKey, debounceValueName1Search], fetchListName1Callback, {
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      if (lastPage.data.page < lastPage.data.totalPage) return lastPage.data.page + 1;
      return undefined;
    },
  });

  const handleScrollToBottomName1 = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const target = e.target as HTMLElement;
      const isAtBottom = Math.abs(target.scrollHeight - (target.scrollTop + target.clientHeight)) <= 1;
      if (!isAtBottom || !name1HasNextPage) return;

      name1FetchNextPage();
    },
    [name1FetchNextPage, name1HasNextPage],
  );

  const {
    data: listName2Data,
    isLoading: isListName2Loading,
    fetchNextPage: name2FetchNextPage,
    hasNextPage: name2HasNextPage,
    //refetch: refetchName2,
  } = useInfiniteQuery(['listName2Data' + props.modalKey, debounceValueName2Search], fetchListName2Callback, {
    // keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      if (lastPage.data.page < lastPage.data.totalPage) return lastPage.data.page + 1;
      return undefined;
    },
  });

  const handleScrollToBottomName2 = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const target = e.target as HTMLElement;
      const isAtBottom = Math.abs(target.scrollHeight - (target.scrollTop + target.clientHeight)) <= 1;
      if (!isAtBottom || !name2HasNextPage) return;

      name2FetchNextPage();
    },
    [name2FetchNextPage, name2HasNextPage],
  );

  const optionsListName1 = useMemo(() => {
    const result: OptionsSelectType[] = [];
    if (!listName1Data) return [];
    // if (!valueObjectId) return [];
    // if (values?.role_id) return []; //will cause trouble in object-role/add popup

    listName1Data.pages.forEach((page) => {
      let excludeId: any = {};
      page.data.objectData?.forEach((v: any) => { excludeId[v[props?.role_id_field || 'role_id']] = v.is_allow; });
      // console.log(page.data.page + (new Date()) + excludeId);
      page.data.data.forEach((row: any) => {
        let disabled = row.id in excludeId;
        if (disabled && row.id === valueRoleId) setValueRoleId(0);    //the valueRoleId will be clear here
        result.push({ id: row.id, name: row[props?.nameField1 || 'name'], disabled });
      });
    });

    return result;
  }, [listName1Data, props, valueRoleId]);

  const optionsListName2 = useMemo(() => {
    const result: OptionsSelectType[] = [];
    if (!listName2Data) return [];
    // if (values?.obsject_id) return []; //will cause trouble in object-role/add popup

    listName2Data.pages.forEach((page) => {
      page.data.data.forEach((row: any) => {
        var name;
        if (props?.nameField2 && props?.nameField2 instanceof Array) {
          name = props?.nameField2.map(v => row[v]).join(', ');
        }
        else name = row['' + props?.nameField2 || 'name'];

        let disabled = false;
        if (props?.role_id_field) {
          if (page.data.objectPermissionData?.find((v: any) => (v['' + props?.role_id_field] === valueRoleId && v.object_id === row.id))) disabled = true;
        }
        else if (props.objectType === 'company') {
          // console.log('type company', row);
          if (row?.companyRole?.find((v: any) => v.role_id === valueRoleId)) disabled = true;
        }
        else if (props.objectType === 'members') {
          // console.log('type member', row);
          if (row?.memberRoles?.find((v: any) => v.role_id === valueRoleId)) disabled = true;
        }
        else if (props.objectType === 'user') {
          // console.log('type user', row);
          if (row?.userRoles?.find((v: any) => v.role_id === valueRoleId)) disabled = true;
        }

        result.push({ id: row.id, name: name, disabled });
      });
    });

    return result;
  }, [listName2Data, props, valueRoleId]);

  const onChangeName1InputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 255) return;
    setSearchName1(e.target.value);
  };
  const onChangeName2InputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 255) return;
    setSearchName2(e.target.value);
  };

  const handleOnFinish = (value: IAddValues) => {
    // console.log('handleOnFinish', value);
    setModalVisible?.(false);
    props?.onModalOK?.(
      {
        rowId: values?.rowId,
        role_id: values?.role_id || valueRoleId || (multipleRole && +multipleRoleValue[0].value) || 0,  //forcibly use the value from arguments
        object_id: values?.object_id || valueObjectId || (multipleObject && +multipleObjectValue[0].value) || 0,   //forcibly use the value from arguments
        is_allow: allowValue,
        start_date: allowValue ? startDate : '', end_date: allowValue ? endDate : '',
        object_type: props.objectType,
      },
      (multipleRole && multipleRoleValue) || undefined,
      (multipleObject && multipleObjectValue) || undefined,
    );
  };

  const onCancel = () => {
    setModalVisible?.(false);
  };

  const dateRule = [
    { required: false, message: 'This field is mandatory' },
    ({ getFieldValue }: any) => ({
      validator(_: any, value: any) {
        //console.log(getFieldValue(varName + '_start_date'));
        if (!value ||
          !getFieldValue(varName + '_start_date') ||
          !getFieldValue(varName + '_end_date') ||
          (getFieldValue(varName + '_start_date').format('YYYY-MM-DD') <= getFieldValue(varName + '_end_date').format('YYYY-MM-DD'))) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Start date cannot be later than End date'));
      },
    }),
  ];

  return (
    <div className={styles.box}>
      <Modal
        {...props}
        onCancel={onCancel}
        centered
        title={props?.title || 'Add Object Role'}
        forceRender
        className={styles.formAddObjectRoleModal}
        width={'50%'}
        closable={true}
        maskClosable={true}
        footer={''}
      >
        <Form onFinish={handleOnFinish} form={form}>
          <Row>
            <Col span={11} >
              <LabelRequire text={props?.role_title || 'Role'} require />
              <SelectSearch
                loading={isListName1Loading}
                optionsSelect={values?.role_name ? [] : optionsListName1}
                onSelect={(id: number) => { if (!multipleRole) { setValueRoleId(id); } }}
                onChange={(values, options) => {
                  if (multipleRole) {
                    const vlist = options.map((v: any) => ({ value: +v.value, label: '' + v.children }));
                    setMultipleRoleValue(vlist);
                  }
                }}
                onClear={() => { setValueRoleId(0); setMultipleRoleValue([]); }}
                onPopupScroll={handleScrollToBottomName1}
                inputSearchProps={{
                  value: searchName1,
                  onChange: onChangeName1InputSearch,
                  onKeyDown: (e: any) => {
                    if (e.key === 'Backspace') return e.stopPropagation();   //prevent backspace remove the selected
                  },
                }}
                onDropdownVisibleChange={() => { setSearchName1(''); }}
                value={multipleRole ? undefined : (values?.role_name ? { id: valueRoleId, label: values?.role_name } : (valueRoleId || ''))}
                defaultValue={multipleRole ? multipleRoleValue : undefined}
                disabled={!!values?.role_id}
                virtual={false}     //scroll bug?
                mode={multipleRole ? 'multiple' : undefined}
              />
            </Col>
            <Col span={1} >
            </Col>
            <Col span={11} >
              <LabelRequire text={props?.objectTypeName || 'Object'} require />
              <SelectSearch
                loading={isListName2Loading}
                optionsSelect={values?.object_name ? [] : optionsListName2}
                onSelect={(id: number) => {
                  if (!multipleObject) {
                    // console.log(id);
                    setValueObjectId(id);
                    // console.log(valueObjectId);
                    setTimeout(refetchName1, 500);  //delay to wait the updating of the valueObjectId
                  }
                }}
                onChange={(values, options) => {
                  if (multipleObject) {
                    const vlist = options.map((v: any) => ({ value: +v.value, label: '' + v.children }));
                    setMultipleObjectValue(vlist);
                  }
                }}
                onClear={() => { setValueObjectId(0); setMultipleObjectValue([]); setTimeout(refetchName1, 500); }}
                onPopupScroll={handleScrollToBottomName2}
                inputSearchProps={{
                  value: searchName2,
                  onChange: onChangeName2InputSearch,
                  onKeyDown: (e: any) => {
                    if (e.key === 'Backspace') return e.stopPropagation();   //prevent backspace remove the selected
                  },
                }}
                onDropdownVisibleChange={() => { setSearchName2(''); }}
                value={multipleObject ? undefined : (values?.object_name ? { id: valueObjectId, label: values?.object_name } : (valueObjectId || ''))}
                defaultValue={multipleObject ? multipleObjectValue : undefined}
                disabled={!!values?.object_id}
                virtual={false}     //scroll bug?
                mode={multipleObject ? 'multiple' : undefined}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '1em' }}>
            <Col span={2}>
              <LabelRequire text='Allow' />
            </Col>
            <Col span={8}>
              <SwitchInput
                text=''
                autoLabelWidth={true}
                id={varName + '-allow'}
                onChange={(checked: boolean) => { setAllowValue(checked); }}
                disabled={false}
                checked={allowValue}
              // className='align-right'
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '1em' }}>
            <Col span={3} style={{ paddingTop: '0.5em' }}>
              <LabelRequire text='Start Date' />
            </Col>
            <Col span={8} >
              <DateInput
                name={varName + '_start_date'}
                id={varName + '_start_date'}
                dependencies={[varName + '_start_date', varName + '_end_date']}
                rules={dateRule}
                dateInputProps={{
                  id: varName + '_start_date',
                  onChange: (value, dateString) => { setStartDate((dateString ? value?.format('YYYY-MM-DD') : '') || ''); },
                  disabled: !allowValue,
                  // value: startMmt,
                  // defaultValue: startMmt,
                }}
                enableAllDate={true}
              // initialValue={startDate ? moment(startDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined}
              />
            </Col>
            <Col span={1} >
            </Col>
            <Col span={3} style={{ paddingTop: '0.5em' }}>
              <LabelRequire text='End Date' />
            </Col>
            <Col span={8} >
              <DateInput
                name={varName + '_end_date'}
                id={varName + '_end_date'}
                dependencies={[varName + '_start_date', varName + '_end_date']}
                rules={dateRule}
                dateInputProps={{
                  id: varName + '_end_date',
                  onChange: (value, dateString) => { setEndDate((dateString ? value?.format('YYYY-MM-DD') : '') || ''); },
                  disabled: !allowValue,
                  // value: endDate ? moment(endDate, 'YYYY-MM-DD') : undefined,
                }}
                enableAllDate={true}
              // initialValue={endDate}
              />
            </Col>

          </Row>

          <div className={styles.alignButton}>
            <CommonButton onClick={onCancel} variant="default" idKey='add-object-role-cancel'>
              Cancel
            </CommonButton>
            <Form.Item shouldUpdate className={styles.addObjectRoleButton}>
              {() => {
                // console.log(form.getFieldsError().filter(({ errors }) => errors.length).length);
                // console.log(allowValue, values?.is_allow);
                // console.log(valueRoleId, valueObjectId);

                let isDisabled = false;
                if (!multipleRole && !multipleObject) {
                  if (
                    !(values?.role_id || valueRoleId) || !(values?.object_id || valueObjectId) ||   //forcibly use the value from arguments
                    (values?.rowId && !allowValue === !values?.is_allow &&
                      isSameDate(startDate, values?.start_date) && isSameDate(endDate, values?.end_date))
                  ) isDisabled = true;
                }

                if (multipleRole || multipleObject) {
                  if (!multipleRoleValue.length && !multipleObjectValue.length) isDisabled = true;
                }

                if (!values?.rowId && !allowValue) isDisabled = true;

                if (form.getFieldsError().filter(({ errors }) => errors.length).length > 0) isDisabled = true;

                return (
                  <CommonButton
                    htmlType="submit"
                    block
                    size="large"
                    variant="primary"
                    disabled={isDisabled}
                    idKey='add-object-role-save'
                  >
                    Save
                  </CommonButton>
                );
              }
              }
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
