import { Col, Form, ModalProps, Row } from 'antd';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { CommonButton } from 'components/common-button';
// import { TextInput } from 'components/form-control';
import LabelRequire from 'components/label-require';
import { ModalBase } from 'components/modal';
import styles from './add-relation-modal.module.scss';
import SelectSearch, { OptionsSelectType } from 'components/select-search/select-search';
import { useInfiniteQuery } from 'react-query';
import { useDebounce } from 'hooks/use-debounce';

export type IAddValues = {
  id1: number;
  id2: number;
  name1?: string;
  name2?: string;
};

export type IPageQuery = {
  page: number,
  pageSize: number,
  search: string,
};

export interface AddRelationModalProps extends ModalProps {
  modalKey: string;
  setModalVisible: Dispatch<SetStateAction<boolean>> | undefined;
  title?: string;
  values?: IAddValues | undefined;
  onModalOK?: (values: IAddValues, valueList?: ValueItem[]) => any;
  fetchListName1: (param: IPageQuery) => Promise<any>;
  fetchListName2: (param: IPageQuery) => Promise<any>;
}

const PAGE_SIZE = 20;

export type ValueItem = {
  value: number;
  label: string;
};

export function AddRelationModal({ setModalVisible, values, ...props }: AddRelationModalProps) {
  const [valueId1, setValueId1] = useState<number>(0);
  const [valueName1, setValueName1] = useState<string>('');
  const [valueId2, setValueId2] = useState<number>(0);
  const [valueName2, setValueName2] = useState<string>('');
  const [searchName1, setSearchName1] = useState<string>('');
  const [searchName2, setSearchName2] = useState<string>('');

  const multipleMode1 = !!(values?.name2 && !values?.name1);
  const [multipleValue1, setMultipleValue1] = useState<ValueItem[]>([]);
  const multipleMode2 = !!(values?.name1 && !values?.name2);
  const [multipleValue2, setMultipleValue2] = useState<ValueItem[]>([]);

  useEffect(() => {
    setValueId1(+(values?.id1 || 0));
    setValueId2(+(values?.id2 || 0));
    setValueName1(values?.name1 || '');
    setValueName2(values?.name2 || '');
    setSearchName1('');
    setSearchName2('');
  }, [
    values,
  ]);

  const debounceValueName1Search = useDebounce<string>(searchName1.trim());
  const debounceValueName2Search = useDebounce<string>(searchName2.trim());

  const fetchListName1Callback = useCallback(
    ({ pageParam = 1 }) => {
      return props.fetchListName1({ page: pageParam, pageSize: PAGE_SIZE, search: debounceValueName1Search });
    },
    [debounceValueName1Search, props],
  );

  const fetchListName2Callback = useCallback(
    ({ pageParam = 1 }) => {
      return props.fetchListName2({ page: pageParam, pageSize: PAGE_SIZE, search: debounceValueName2Search });
    },
    [debounceValueName2Search, props],
  );

  const {
    data: listName1Data,
    isLoading: isListName1Loading,
    fetchNextPage: name1FetchNextPage,
    hasNextPage: name1HasNextPage,
    // refetch: refetchName1,
  } = useInfiniteQuery(['listName1Data' + props.modalKey, debounceValueName1Search], fetchListName1Callback, {
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      if (lastPage.data.page < lastPage.data.totalPage) return lastPage.data.page + 1;
      return undefined;
    },
  });

  const handleScrollToBottomName1 = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const target = e.target as HTMLElement;
      const isAtBottom = Math.abs(target.scrollHeight - (target.scrollTop + target.clientHeight)) <= 1;
      if (!isAtBottom || !name1HasNextPage) return;

      name1FetchNextPage();
    },
    [name1FetchNextPage, name1HasNextPage],
  );

  const {
    data: listName2Data,
    isLoading: isListName2Loading,
    fetchNextPage: name2FetchNextPage,
    hasNextPage: name2HasNextPage,
    // refetch: refetchName2,
  } = useInfiniteQuery(['listName2Data' + props.modalKey, debounceValueName2Search], fetchListName2Callback, {
    // keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      if (lastPage.data.page < lastPage.data.totalPage) return lastPage.data.page + 1;
      return undefined;
    },
  });

  const handleScrollToBottomName2 = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const target = e.target as HTMLElement;
      const isAtBottom = Math.abs(target.scrollHeight - (target.scrollTop + target.clientHeight)) <= 1;
      if (!isAtBottom || !name2HasNextPage) return;

      name2FetchNextPage();
    },
    [name2FetchNextPage, name2HasNextPage],
  );

  const optionsListName1 = useMemo(() => {
    const result: OptionsSelectType[] = [];
    if (!listName1Data) return [];

    let excludeId = new Set();
    listName1Data.pages.forEach((page) => {
      page.data.relationData?.forEach((v: any) => { if ((+ v.permission_id) === valueId2) excludeId.add(v.role_id); });
    });

    listName1Data.pages.forEach((page) => {
      page.data.data.forEach(({ id, name }: any) => {
        const disabled = excludeId.has(id);
        result.push({ id, name, disabled });
      });
    });

    return result;
  }, [listName1Data, valueId2]);

  const optionsListName2 = useMemo(() => {
    const result: OptionsSelectType[] = [];
    if (!listName2Data) return [];

    let excludeId = new Set();
    listName2Data.pages.forEach((page) => {
      page.data.relationData?.forEach((v: any) => { if ((+ v.role_id) === valueId1) excludeId.add(v.permission_id); });
    });

    // console.log('optionsListName2', valueId1, excludeId);

    listName2Data.pages.forEach((page) => {
      page.data.data.forEach(({ id, name }: any) => {
        const disabled = excludeId.has(id);
        result.push({ id, name, disabled });
      });
    });

    return result;
  }, [listName2Data, valueId1]);

  const onChangeName1InputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 255) return;
    setSearchName1(e.target.value);
  };
  const onChangeName2InputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 255) return;
    setSearchName2(e.target.value);
  };

  const handleOnFinish = (value: IAddValues) => {
    // console.log(value);
    setModalVisible?.(false);
    props?.onModalOK?.(
      { id1: valueId1, id2: valueId2, name1: valueName1, name2: valueName2 },
      (multipleMode1 && multipleValue1) || (multipleMode2 && multipleValue2) || undefined,
    );
  };

  const onCancel = () => {
    setModalVisible?.(false);
  };

  return (
    <div className={styles.box}>
      <ModalBase
        {...props}
        onCancel={onCancel}
        centered
        title={props?.title || 'Add Relation'}
        forceRender
        className={styles.formAddRelationModal}
      >
        <Form onFinish={handleOnFinish}>
          <Row>
            <Col span={11} >
              <LabelRequire text="Role" require />
              <SelectSearch
                loading={isListName1Loading}
                optionsSelect={optionsListName1}
                onSelect={(id: any, option: any) => {
                  if (!multipleMode1) {
                    setValueId1(+id || 0);
                    setValueName1('' + option.children);
                  }
                }}
                onChange={(values, options) => {
                  if (multipleMode1) {
                    const vlist = options.map((v: any) => ({ value: +v.value, label: '' + v.children }));
                    setMultipleValue1(vlist);
                  }
                }}
                onClear={() => {
                  setValueName1('');
                  setValueId1(0);
                  setMultipleValue1([]);
                }}
                onPopupScroll={handleScrollToBottomName1}
                inputSearchProps={{
                  value: searchName1,
                  onChange: onChangeName1InputSearch,
                  onKeyDown: (e: any) => {
                    if (e.key === 'Backspace') return e.stopPropagation();   //prevent backspace remove the selected
                  },
                }}
                onDropdownVisibleChange={() => { setSearchName1(''); }}
                value={multipleMode1 ? undefined : valueName1}
                defaultValue={multipleMode1 ? multipleValue1 : undefined}
                disabled={!!values?.name1}
                virtual={false}     //scroll bug?
                mode={multipleMode1 ? 'multiple' : undefined}
                dropdownMatchSelectWidth={false}
              />
            </Col>
            <Col span={1} >
            </Col>
            <Col span={11} >
              <LabelRequire text="Permission" require />
              <SelectSearch
                loading={isListName2Loading}
                optionsSelect={optionsListName2}
                onSelect={(id: any, option: any) => {
                  if (!multipleMode2) {
                    setValueId2(+id || 0);
                    setValueName2('' + option.children);
                  }
                }}
                onChange={(values, options) => {
                  if (multipleMode2) {
                    const vlist = options.map((v: any) => ({ value: +v.value, label: '' + v.children }));
                    setMultipleValue2(vlist);
                  }
                }}
                onClear={() => {
                  setValueName2('');
                  setValueId2(0);
                  setMultipleValue2([]);
                }}
                onPopupScroll={handleScrollToBottomName2}
                inputSearchProps={{
                  value: searchName2,
                  onChange: onChangeName2InputSearch,
                  onKeyDown: (e: any) => {
                    if (e.key === 'Backspace') return e.stopPropagation();   //prevent backspace remove the selected
                  },
                }}
                onDropdownVisibleChange={() => { setSearchName2(''); }}
                value={multipleMode2 ? undefined : valueName2}
                defaultValue={multipleMode2 ? multipleValue2 : undefined}
                disabled={!!values?.name2}
                virtual={false}     //scroll bug?
                mode={multipleMode2 ? 'multiple' : undefined}
                dropdownMatchSelectWidth={false}
              />
            </Col>
          </Row>

          <div className={styles.alignButton}>
            <CommonButton onClick={onCancel} variant="default" idKey='add-relation-cancel'>
              Cancel
            </CommonButton>
            <Form.Item shouldUpdate className={styles.addRelationButton}>
              {() => {
                // console.log('update btn');
                return (
                  <CommonButton
                    htmlType="submit"
                    block
                    size="large"
                    variant="primary"
                    disabled={
                      ((!multipleMode1 && !multipleMode2) &&
                        (!valueName1 || !valueName2 || (valueName1 === values?.name1 && valueName2 === values.name2))) ||
                      ((multipleMode1 || multipleMode2) &&
                        (!multipleValue1.length && !multipleValue2.length)
                      )
                    }
                    idKey='add-relation-save'
                  >
                    Save
                  </CommonButton>
                );
              }
              }
            </Form.Item>
          </div>
        </Form>
      </ModalBase>
    </div>
  );
}
