import '../external-user/external-user.scss';
import TabCommon from 'components/tab-common';
// import { listTab } from '../external-user';
// import BoAdmin from '../internal-user/bo-admin';
import Permissions from './permissions';
import Roles from './roles';
import RolePermission from './role-permission';
import ObjectRoles from './object-roles';
import Permission from './permission';
import ObjectPermission from './object-permission';

export interface IExternalUserProps { }

export default function Log(props: IExternalUserProps) {

  const listTabEx = [
    {
      key: 'Roles',
      name: 'Roles',
      component: <Roles />,
    },
    {
      key: 'Permissions',
      name: 'Permissions',
      component: <Permissions />,
    },
    {
      key: 'RolePermission',
      name: 'Role-Permission',
      component: <RolePermission />,
    },
    {
      key: 'ObjectRoles',
      name: 'Object-Role',
      component: <ObjectRoles />,
    },
    {
      key: 'Permission',
      name: 'Standalone Permission',
      component: <Permission />,
    },
    {
      key: 'ObjectPermission',
      name: 'Object-Standalone Permission',
      component: <ObjectPermission />,
    },
  ];

  return (
    <div className="external-user">
      <div className="content">
        <TabCommon listTab={listTabEx} />
      </div>
    </div>
  );
}
