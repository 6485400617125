import { ExternalUserType } from 'modules/user-management/external-user/external-user.const';
import axiosClient from 'utils/axios-client';
import {
  changeCompanyConfigurationParams,
  changeRestrictionParams,
  CityType,
  CountryType,
  CreateCompanyType,
  GetListCompanyParamsType,
  ImportBatchBodyType,
  ListResponseType,
  ResponseType,
  RolesCompanyResponse,
  UpdateAccountDto,
  UpdateMemberDto,
  UserExternalBody,
} from './api-external-user.type';
import { UserCompanyType } from 'modules/user-management/external-user/company/company-user-modal/company-user-modal.const';
import { ChangeCompanyPropertyBody, FetchCompanyProperty } from './api-company-user.type';

export const externalUserApi = {
  createCompany(body: CreateCompanyType): Promise<ResponseType<any>> {
    return axiosClient.post('/company', body);
  },
  getListCompany(params: GetListCompanyParamsType): Promise<ListResponseType<ExternalUserType>> {
    return axiosClient.get('/company', {
      params,
    });
  },
  getListCompanyWithPermission(params: GetListCompanyParamsType): Promise<ListResponseType<ExternalUserType>> {
    return axiosClient.get('/company/with-permission', {
      params,
    });
  },
  getListMembers(params: GetListCompanyParamsType): Promise<ListResponseType<ExternalUserType>> {
    return axiosClient.get('/members', {
      params,
    });
  },
  getListMembersWithPermission(params: GetListCompanyParamsType): Promise<ListResponseType<ExternalUserType>> {
    return axiosClient.get('/members/with-permission', {
      params,
    });
  },

  getListRoles(params: GetListCompanyParamsType): Promise<ListResponseType<any>> {
    return axiosClient.get('/roles', { params: params });
  },
  getListPermission(params: GetListCompanyParamsType): Promise<ListResponseType<any>> {
    return axiosClient.get('/permission', { params: params });
  },
  getListRolesWithRelation(params: GetListCompanyParamsType): Promise<ListResponseType<any>> {
    return axiosClient.get('/roles/with-relation', { params: params });
  },
  getListRolesWithObject(params: GetListCompanyParamsType): Promise<ListResponseType<any>> {
    return axiosClient.get('/roles/with-object', { params: params });
  },
  getListPermissionWithObject(params: GetListCompanyParamsType): Promise<ListResponseType<any>> {
    return axiosClient.get('/permission/with-object', { params: params });
  },
  getListPermissions(params: GetListCompanyParamsType): Promise<ListResponseType<any>> {
    return axiosClient.get('/permissions', { params: params });
  },
  getListPermissionsWithRelation(params: GetListCompanyParamsType): Promise<ListResponseType<any>> {
    return axiosClient.get('/permissions/with-relation', { params: params });
  },

  getListCountries(): Promise<ResponseType<CountryType[]>> {
    return axiosClient.get('/countries');
  },
  getListCities(countryCode: string): Promise<ResponseType<CityType[]>> {
    return axiosClient.get('/countries/cities', {
      params: {
        country: countryCode,
      },
    });
  },
  getListRoleCompany(): Promise<ResponseType<RolesCompanyResponse>> {
    return axiosClient.get('/roles/company');
  },
  createNewUser(body: UserExternalBody): Promise<ResponseType<any>> {
    return axiosClient.post('/members', body);
  },
  importBatchUserList(body: ImportBatchBodyType): Promise<ResponseType<any>> {
    return axiosClient.post('/members/batch', body);
  },
  changeCompanyConfiguration(id: string, params: changeCompanyConfigurationParams) {
    return axiosClient.put(`/company/${id}`, params);
  },
  isCompanyKeyExist(companyKey: string) {
    return axiosClient.get(`/company/company-key/exist/${companyKey}`);
  },
  isInvitationCodeExist(invitationCode: string) {
    return axiosClient.get(`/company/invitation-code/exist/${invitationCode}`);
  },
  getCompanyProperty(companyID: number): Promise<FetchCompanyProperty> {
    return axiosClient.get(`/company/property/${companyID}`);
  },
  changeCompanyProperty(companyID: number, body: ChangeCompanyPropertyBody): Promise<any> {
    return axiosClient.post(`/company/property/${companyID}`, body);
  },

  changeMemberRestriction(id: string, params: changeRestrictionParams) {
    return axiosClient.patch(`/members/restriction/${id}`, params);
  },

  changeUserRestriction(id: string, params: changeRestrictionParams) {
    return axiosClient.patch(`/user/restriction/${id}`, params);
  },

  updateMember(id: string, params: UpdateMemberDto) {
    return axiosClient.patch(`/members/${id}`, params);
  },

  getAccountList(params: GetListCompanyParamsType): Promise<ListResponseType<UserCompanyType>> {
    return axiosClient.get('/account', {
      params,
    });
  },

  setAccountAvailability(id: string, params: UpdateAccountDto) {
    return axiosClient.post(`/account/set-availability/${id}`, params);
  },

  postResendNewMemberNotice(memberId: string) {
    return axiosClient.post(`/members/resend-new-member-email/${memberId}`);
  },

  deleteMember(id: string, email: string) {
    return axiosClient.delete(`/members/${id}`, { data: { email: email } });
  },
};
