import { Tabs } from 'antd';
import clsx from 'clsx';

import { ITab } from './tab-common';

import styles from './tab-common.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { pushParamHandle } from 'modules/user-management/external-user/company/company.const';
import { useParseParams } from 'hooks/use-params';

export interface ITabCommonProps {
  className?: string;
  listTab: ITab[];
}

const { TabPane } = Tabs;

const TabCommon = ({ className, listTab }: ITabCommonProps) => {
  const history = useHistory();
  const location = useLocation();

  const { tabKey } = useParseParams();

  function onTabChange(v: string) {
    pushParamHandle(
      location.pathname,
      { tabKey: v },    //remove pages
      history,
    );
  }

  //console.log('tabKey', tabKey);

  return (
    <div className={clsx(styles.tabs, className)}>
      <Tabs
        defaultActiveKey={listTab.find((v) => v.key === tabKey)?.key || listTab[0].key}
        activeKey={listTab.find((v) => v.key === tabKey)?.key || listTab[0].key}
        onWheelCapture={(e) => e.stopPropagation()}
        destroyInactiveTabPane
        onChange={onTabChange}
      >
        {listTab.map((item: ITab) => (
          <TabPane tab={item.name} key={item.key}>
            {item.component}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default TabCommon;
