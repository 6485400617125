import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { useInfiniteQuery, useQuery } from 'react-query';
import { Menu, Modal, Spin, message, notification } from 'antd';

import { TextInput } from 'components/form-control';
import { CheckCircleIcon, CloseIcon, SearchIcon, XCircleIcon } from 'components/icons';
import { CustomPagination } from 'components/pagination';
import Table from 'components/table';
//import { CompanyUserModal } from './company-user-modal';
import SortingArrow from 'components/sorting-arrow';
import { GetListCompanyParamsType } from 'services/api-external-user.type';
import { externalUserApi } from 'services/api-external-user.services';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { renderSorterOrder } from 'components/table/table.const';
//import SelectFilter from './select-filter';
import ChangePlanModal from '../company/change-plan-modal';
import { NoDataTable } from 'components/no-data-table';
import { useHistory, useLocation } from 'react-router-dom';
import { useParseParams } from 'hooks/use-params';
import { pushParamHandle } from '../company/company.const';
import { KioskTableData, loadCompanyKioskTable, loadMembersKioskTable } from '../company/change-plan-modal/kiosk-data';
import { namedMappingRev, roleDefault } from '../company/change-plan-modal/role-tool';
import { isSysAdmin } from 'redux/has-permission';
import { UserCompanyType } from '../company/company-user-modal/company-user-modal.const';
import { isNil } from 'utils/helper';
import { StatusDisplayer, StatusType } from '../company/company-user-modal/status-displayer';
import moment from 'moment';
import styles from './style.module.scss';
import { getCompanyUserList } from 'services/api-company-user.service';
//import SelectFilter from '../company/select-filter';
import SelectSearch, { OptionsSelectType } from 'components/select-search/select-search';
import { useDebounce } from 'hooks/use-debounce';
import { RowActionMenu, RowActionMenuProps } from '../company/row-action-menu';
//import LabelRequire from 'components/label-require';
import { keyBy } from 'lodash';
import { loadMemberObjectRole } from '../company/change-plan-modal/blueonionhub-data';
import dayjs from 'dayjs';
import { EllipsisCell } from '../account/ellipsis-cell';
import { ObjectRolesModal } from 'modules/user-management/settings/object-roles';
import axiosClient from 'utils/axios-client';
import { ObjectPermissionModal } from 'modules/user-management/settings/object-permission';

const ACTION_LABEL = {
  CHANGE_PLAN: 'Change Plan',
  ROLES_RELATIONSHIP: 'Role Relationship',
  PERMISSION_RELATIONSHIP: 'Standalone Permission Relationship',
  ENABLE: 'Enable',
  DISABLE: 'Disable',
  DELETE: 'Delete',
  RESEND_NOTICE: 'Resend Notice',
};

function ActionMenu({ row, className, action, ...props }: RowActionMenuProps) {
  var isCompanyAdmin = !isSysAdmin();
  const menu = (
    <Menu className={className} style={{ width: 260 }} >
      <Menu.Item key='Change Plan' onClick={() => { action(ACTION_LABEL.CHANGE_PLAN, row); }}>Change Plan</Menu.Item>
      {!isCompanyAdmin && <Menu.Item key='Roles Relationship' onClick={() => { action(ACTION_LABEL.ROLES_RELATIONSHIP, row); }}>Role Relationship</Menu.Item>}
      {!isCompanyAdmin && <Menu.Item key='Permission Relationship' onClick={() => { action(ACTION_LABEL.PERMISSION_RELATIONSHIP, row); }}>Standalone Permission Relationship</Menu.Item>}
      <Menu.Divider />
      <Menu.Item key='Enable' onClick={() => { action(ACTION_LABEL.ENABLE, row); }} disabled={parseInt(row.status) !== 0}>Enable</Menu.Item>
      <Menu.Item key='Disable' onClick={() => { action(ACTION_LABEL.DISABLE, row); }} disabled={parseInt(row.status) !== 1}>Disable</Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={() => { action(ACTION_LABEL.DELETE, row); }}>Delete</Menu.Item>
      {
        !row.last_login && row.status === 1 && <>
          <Menu.Divider />
          <Menu.Item onClick={() => { action(ACTION_LABEL.RESEND_NOTICE, row); }}>Resend Notice</Menu.Item>
        </>
      }
    </Menu>
  );
  return <RowActionMenu {...{ row, className, action, ...props, menu }} />;
}

type MemberProps = {
  onlyCompanyAdmin?: boolean;
  companyId?: string
};

export default function Member({ onlyCompanyAdmin, companyId, ...props }: MemberProps) {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const companyIdFromUrl = searchParams.get('companyId');
  const { pageSize, page, search, sortOrder, sortBy, roleIds, tabKey, sortable } = useParseParams();

  const [changePlanVisible, setChangePlanVisible] = useState<boolean>(false);
  const [rendered, setRendered] = useState<boolean>(false);
  //const [visibleCompany, setVisibleCompany] = useState<boolean>(false);
  const [valueSearch, setValueSearch] = useState<string>(search);
  const [companyName, setCompanyName] = useState<string>('');
  const [companyDisplayName, setCompanyDisplayName] = useState<string | null>(companyName || null);
  const debounceValueCompanySearch = useDebounce<string>(companyName.trim());

  const [companyDetails, setCompanyDetails] = useState({
    id: '',
    // name: '',
    // domain: '',
    // plan_start_date: '',
    // plan_end_date: '',
    // is_new_member_need_audit: false,
    // is_accept_public_email: false,

    // ...roleDefault,

    // report_quota: 0,
    // number_of_license: 0,
  });

  /*
  const [paginationData, setPaginationData] = useState<PaginationDataType>({
    currentPage: 1,
    orderBy: 'id',
    order: SortOrderEnum.DESC,
    pageSize: 10,
  });
  */

  //kisok data

  const [kioskTableData, setKioskTableData] = useState<KioskTableData | null>();
  function onKioskUpdateData(newKioskTableData: KioskTableData) {
    setKioskTableData(newKioskTableData);
  }

  const [companyKioskTableData, setCompanyKioskTableData] = useState<KioskTableData | null>();

  const [isOpenObjectRolesModal, setOpenObjectRolesModal] = useState<boolean>(false);

  const [objectRolesModalArgs, setObjectRolesModalArgs] = useState<any>({
    modalKey: 'ObjectRolesModal-from-member',
    open: true,
    setModalVisible: setOpenObjectRolesModal,
  });

  const [isOpenObjectPermissionModal, setOpenObjectPermissionModal] = useState<boolean>(false);

  const [objectPermissionModalArgs, setObjectPermissionModalArgs] = useState<any>({
    modalKey: 'ObjectPermissionModal-from-member',
    open: true,
    setModalVisible: setOpenObjectPermissionModal,
  });

  const setParamHandle = useCallback(
    (data: GetListCompanyParamsType) => {
      pushParamHandle(
        location.pathname,
        {
          page: page ?? 1,
          pageSize: pageSize ?? 20,
          search,
          sortOrder: sortOrder ?? 'asc',
          sortBy: sortBy ?? 'id',
          roleIds,
          sortable: sortable || undefined,
          ...data,
        },
        history,
      );
    },
    [history, location.pathname, page, pageSize, roleIds, search, sortBy, sortOrder, sortable],
  );

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueSearch(e.target.value);
    setTimeout(() => {
      setParamHandle({
        page: 1, search: e.target.value, tabKey,
        companyId: companyIdFromUrl ? companyIdFromUrl : companyId ? companyId : companyDetails?.id,
      });
    }, 700);
  };

  /*
  const fetchListCompany = useCallback(() => {
    return externalUserApi.getListCompany({
      page,
      pageSize,
      sortBy,
      sortOrder,
      search: search?.trim(),
      roleIds,
    });
  }, [page, pageSize, roleIds, search, sortBy, sortOrder]);
  */

  const fetchListCompanyRoles = useCallback(() => {
    return externalUserApi.getListRoleCompany();
  }, []);

  const { data: listCompanyRolesData } = useQuery(['listCompanyRolesCompanyView'], fetchListCompanyRoles);

  /*
  const params = useMemo(
    () => ({
      page: paginationData.currentPage || 0,
      pageSize: 10,
      sortBy: paginationData.orderBy || 'id',
      sortOrder: paginationData.order || 'desc',
      search: '',
      status: '',
      companyId: companyDetails?.id,
    }),
    [paginationData.currentPage, paginationData.order, paginationData.orderBy, companyDetails?.id],
  );
  */

  const {
    data: listMemberData,
    isLoading: isListMemberLoading,
    refetch } = useQuery(
      [
        'getListMember',
        {
          pageSize,
          page,
          //status,
          search,
          sortBy,
          sortOrder,
          companyId: companyIdFromUrl ? companyIdFromUrl : companyId ? companyId : companyDetails?.id,
          admin: !!onlyCompanyAdmin,
        },
      ], () => {
        return getCompanyUserList({
          pageSize,
          page,
          status: undefined,  //urlStatus !== 'active,disabled' ? urlStatus : undefined,
          search: search?.trim(),
          sortBy,
          sortOrder,
          companyId: companyIdFromUrl ? companyIdFromUrl : companyId ? companyId : companyDetails?.id,
          admin: !!onlyCompanyAdmin,
        });
      });

  //console.log('listMemberData', listMemberData);

  /*
  const {
   data: listCompanyData,
   isLoading: isListCompanyLoading,
   refetch,
  } = useQuery(
   [
     'listCompanyDataUserExternal',
     {
       page,
       pageSize,
       sortBy,
       sortOrder,
       search: search?.trim(),
       roleIds,
     },
   ],
   fetchListCompany,
  );
  */

  useEffect(() => {
    if (!listCompanyRolesData?.data.blueonionHubRoles) return;
    setRendered(true);
    const listRoleIds = !rendered ? listCompanyRolesData.data.blueonionHubRoles.map((item) => item.id) : roleIds;
    if (listCompanyRolesData.data.blueonionHubRoles.map((item) => item.id).toString() === roleIds) return;
    !rendered &&
      setParamHandle({
        roleIds: roleIds ?? listRoleIds?.toString(),
        tabKey,
      });
    return () => {
      setRendered(false);
    };
  }, [listCompanyRolesData?.data.blueonionHubRoles, rendered, roleIds, setParamHandle, tabKey]);

  /*
  const blueOnionHubList = useCallback((listItems: []) => {
    return blueOnionHubConfig.map(({ key, title, status, color, bg, acronym }) => {
      const items: string[] = [];
      listItems.forEach((item: any) => {
        items.push(item.role.name);
      });
      if (items.includes(title)) {
        return {
          key,
          status: true,
          color,
          bg,
          acronym,
        };
      }
      return {
        key,
        status,
        color,
        bg,
        acronym,
      };
    });
  }, []);
  */

  const onChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserCompanyType>[] | SorterResult<any>,
  ) => {
    setParamHandle({
      sortBy: (sorter as SorterResult<UserCompanyType>).field as string,
      sortOrder: renderSorterOrder((sorter as SorterResult<UserCompanyType>).order as string),
      tabKey,
    });
  };

  const renderTitle = (title: string, sortingFor?: string, tooltip?: string) => (
    <div className="renderTitle" key={title}>
      <div className="title">{title}</div>
      <SortingArrow order={sortOrder} orderBy={sortBy} sortingFor={sortingFor} />
    </div>
  );

  const [memberDetails, setMemberDetails] = useState({
    id: '',
    name: '',
    username: '',

    ...roleDefault,

    start_date: '',
    expired_date: '',
    plan_start_date: '',
    plan_end_date: '',
    is_isolate_role: false,
    is_admin: false,

    blueOnionHubRole: {},
    company: {},
  });

  const handleChangePlan = (value: UserCompanyType) => {
    setMemberDetails((prev) => ({
      ...prev,
      ...roleDefault,
    }));

    value.memberRoles.forEach((item) => {
      if (item.is_allow && !item.is_prohibit && item.role.name in namedMappingRev) {
        setMemberDetails((prev) => ({
          ...prev,
          [namedMappingRev[item.role.name]]: true,
        }));
      }
    });

    setMemberDetails((prev) => ({
      ...prev,
      id: value.id,
      name: value.name || '',
      username: value.username || '',
      email: value.email,

      expired_date: value.expired_date,
      start_date: value.start_date,
      plan_start_date: value.plan_start_date,
      plan_end_date: value.plan_end_date,

      is_isolate_role: value.is_isolate_role,
      is_admin: value.is_admin,
      is_receive_expiry_notify: value.is_receive_expiry_notify,
      cooling_start_date: value.cooling_start_date,

      account_id: value.account_id,
      handleKey: (new Date()).getTime(),

      company: value.company,
    }));


    setKioskTableData(null);
    loadMembersKioskTable(+value.id, kioskTableData).then((res) => {
      setKioskTableData(res);
      //console.log('company kiosk data loaded', res);
      loadCompanyKioskTable(+value.company_id, companyKioskTableData || kioskTableData).then((res) => {
        setCompanyKioskTableData(res);
        setChangePlanVisible(true);
      });
    });

    loadMemberObjectRole(+value.id).then((objectRole) => {
      const nameMappedMemberObjectRole = objectRole.map((role) => {
        return { ...role, role_mapped_name: namedMappingRev[role.role_name] };
      });
      setMemberDetails((prev) => ({
        ...prev,
        blueOnionHubRole: keyBy(nameMappedMemberObjectRole, 'role_mapped_name'),
      }));
    });
  };

  var isCompanyAdmin = !isSysAdmin();

  const handleObjectRoles = (row: any) => {
    setObjectRolesModalArgs({
      ...objectRolesModalArgs,
      title: 'Roles for Member [' + row.username + ']',
      role_id: null,
      role_name: null,
      object_type: 'members',
      object_id: row.id,
      object_name: row.username + ', ' + row.email,
    });
    setOpenObjectRolesModal(true);
  };

  const handleObjectPermission = (row: any) => {
    setObjectPermissionModalArgs({
      ...objectPermissionModalArgs,
      title: 'Standalone Permission for Member [' + row.username + ']',
      permission_id: null,
      permission_name: null,
      object_type: 'members',
      object_id: row.id,
      object_name: row.username + ', ' + row.email,
    });
    setOpenObjectPermissionModal(true);
  };

  const handleDeleteMember = (row: any) => {
    var value: any = '';
    var value2: any = '';

    var modal = Modal.confirm({
      title: 'Confirm Deleting',
      content: <p>To confirm the deleting of member <span style={{ color: 'green' }}>{row.username}</span>&nbsp;
        with the email <span style={{ color: 'green' }}>{row.email}</span>,
        please type or paste the member's username and email below.<br />
        <br />
        The member &nbsp;
        <input onChange={(evt) => {
          value = evt.target.value?.trim();
          modal.update({ okButtonProps: { disabled: value !== row.username || value2 !== row.email } });
        }} style={{ marginBottom: '0.3em' }} /> with email &nbsp;
        <input onChange={(evt) => {
          value2 = evt.target.value?.trim();
          modal.update({ okButtonProps: { disabled: value !== row.username || value2 !== row.email } });
        }} style={{ marginBottom: '0.3em' }} />
        &nbsp; will be deleted.
      </p>,
      width: 600,
      okButtonProps: { disabled: true },
      closable: true,
      maskClosable: true,
      onOk: () => {
        // console.log(value);
        axiosClient.delete('/members/' + row.id, { data: { email: row.email } }).then((res) => {
          // console.log(res);
          message.success('Success to delete a member');
          refetch();
        }).catch((ex) => {
          // console.log(ex);
          message.error('Error: ' + (ex?.response?.data?.message || ex));
        });

      },
    });

  };

  async function onMemberAction(label: string, row: any) {
    if (label === ACTION_LABEL.CHANGE_PLAN) handleChangePlan(row);
    else if (label === ACTION_LABEL.ROLES_RELATIONSHIP) handleObjectRoles(row);
    else if (label === ACTION_LABEL.PERMISSION_RELATIONSHIP) handleObjectPermission(row);
    else if (label === ACTION_LABEL.DISABLE || label === ACTION_LABEL.ENABLE) {
      await externalUserApi.updateMember(row.id, { status: (label === ACTION_LABEL.DISABLE) ? 0 : 1 });
      refetch();
    }
    else if (label === ACTION_LABEL.RESEND_NOTICE) {
      Modal.confirm(
        {
          content: <div>
            <p>Please confirm the following process,</p>
            <p>1. Generate a new random password for the email '{row.email}'.</p>
            <p>2. Send a welcome notice to the email '{row.email}' with the new password and company name '{row.company.name}'.</p>
            <p>Note: all member users related to this email will use the new password.</p>
            <p>Please confirm.</p>
          </div>,
          onOk: async () => {
            const ret = await externalUserApi.postResendNewMemberNotice(row.id);
            //console.log(ret);
            if (ret.data) {
              notification.success({
                message: 'Resend successfully.',
                description: 'Resend new member notice successfully.',
                placement: 'bottomRight',
                closeIcon: <CloseIcon fill="#ffffff" width="12" height="12" />,
                icon: <CheckCircleIcon />,
                className: styles.importBatchSuccessNoti,
              });
            }
            else {
              notification.error({
                message: 'Resend fail.',
                description: 'Resend new member notice fail.',
                placement: 'bottomRight',
                closeIcon: <CloseIcon fill="#ffffff" width="12" height="12" />,
                icon: <CheckCircleIcon />,
                className: styles.importBatchSuccessNoti,
              });
            }
          },
          width: 700,
        },
      );
    }
    else if (label === ACTION_LABEL.DELETE) {
      handleDeleteMember(row);
    }
  }

  const nowDayStr = dayjs().format('YYYY-MM-DD');

  const columns: ColumnsType<UserCompanyType> = [
    {
      align: 'left',
      title: renderTitle('Email', 'email'),
      dataIndex: 'email',
      key: 'email',
      width: 180,
      sorter: true,
      //ellipsis: true,
      render: (_, row) => <EllipsisCell textValue={row.email} />,
    },
    {
      align: 'left',
      title: renderTitle('Account', 'username'),
      dataIndex: 'username',
      key: 'username',
      width: 150,
      sorter: true,
      //ellipsis: true,
      render: (_, row) => <EllipsisCell textValue={row.username} />,
    },
    {
      align: 'left',
      title: renderTitle('Company', 'company.name'),
      dataIndex: 'company.name',
      key: 'CompanyName',
      width: 150,
      sorter: true,
      //render: (_, row) => row.company.name,
      //ellipsis: true,
      render: (_, row) => <EllipsisCell textValue={row.company.name} />,
    },
    {
      align: 'left',
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (country: string) => <div>{isNil(country) ? '-' : country}</div>,
    },
    {
      align: 'left',
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      render: (city: string) => <div>{isNil(city) ? '-' : city}</div>,
    },
    {
      align: 'left',
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
      render: (phone: string) => <div>{isNil(phone) ? '-' : phone}</div>,
    },
    {
      align: 'left',
      title: renderTitle('Status', 'status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text: StatusType) => (
        <div className={styles.statusColumn}>
          <StatusDisplayer value={text} />
        </div>
      ),
    },
    {
      align: 'left',
      title: renderTitle('Expired Date', 'expired_date'),
      dataIndex: 'expired_date',
      key: 'expired_date',
      sorter: true,
      render: (expired_date: string) => (
        <div style={(expired_date && expired_date < nowDayStr) ? { color: '#FF424F' } : {}}>
          {isNil(expired_date) ? '-' : moment(expired_date).format('YYYY-MM-DD')}
        </div>
      ),
    },
    {
      align: 'left',
      title: renderTitle('Last Login', 'last_login'),
      dataIndex: 'last_login',
      key: 'last_login',
      sorter: true,
      render: (last_login: string) => <div>{isNil(last_login) ? '-' : moment(last_login).format('YYYY-MM-DD HH:mm:ss')}</div>,
    },
    {
      title: 'Action',  //'Actions',  //too wide
      dataIndex: 'changeMemberPlane',
      key: 'changeMemberPlane',
      width: 80,
      align: 'center',
      fixed: 'right',

      render: (_, data: UserCompanyType) => {
        return (
          (isCompanyAdmin && data.is_admin) ? '' :
            <ActionMenu row={data} className={'actionMenu'} action={onMemberAction} />
        );
      },

    },
  ];

  const ClearFilters = () => {
    const listRoleIds = listCompanyRolesData?.data.blueonionHubRoles.map((item) => item.id);
    setParamHandle({ page: 1, pageSize: 20, roleIds: listRoleIds?.toString(), search: undefined, tabKey });
    setValueSearch('');
    onCompanyClear();
  };

  const onChangePage = (page: number, pageSize: number) => {
    //console.log('page', [page, pageSize]);
    //if (page > pageSize) return;  //bug?
    setParamHandle({
      page, pageSize, roleIds: roleIds, tabKey,
      companyId: companyIdFromUrl ? companyIdFromUrl : companyId ? companyId : companyDetails?.id,
    });
  };

  /*
  const handleChange = (value: string[]) => {
    setParamHandle({ roleIds: value.toString(), tabKey });
  };
  */

  const totalResult = useMemo(() => {
    if (!roleIds?.length) {
      return 0;
    }
    return listMemberData?.total ?? 0;
  }, [listMemberData?.total, roleIds?.length]);

  ////////     Filter by Company      ////////

  const PAGE_SIZE = 7;

  const fetchListCompany = useCallback(
    ({ pageParam = 1 }) => {
      return externalUserApi.getListCompany({
        page: pageParam,
        pageSize: PAGE_SIZE,
        search: debounceValueCompanySearch,
      });
    },
    [debounceValueCompanySearch],
  );

  const onChangeCompanyInputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 255) return;
    setCompanyName(e.target.value);
  };

  const {
    data: listCompanyData,
    isLoading: isListCompanyLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(['listCompanyData', debounceValueCompanySearch], fetchListCompany, {
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      if (lastPage.data.page < lastPage.data.totalPage) return lastPage.data.page + 1;
      return undefined;
    },
  });

  const optionsListCompany = useMemo(() => {
    const result: OptionsSelectType[] = [];
    if (!listCompanyData) return [];

    listCompanyData.pages.forEach((page) => {
      page.data.data.forEach(({ id, name }) => {
        result.push({ id, name });
      });
    });

    return result;
  }, [listCompanyData]);


  const handleScrollToBottom = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const target = e.target as HTMLElement;
      const isAtBottom = Math.abs(target.scrollHeight - (target.scrollTop + target.clientHeight)) <= 1;
      if (!isAtBottom || !hasNextPage) return;

      fetchNextPage();
    },
    [fetchNextPage, hasNextPage],
  );

  const onCompanyDropdownVisibleChange = () => {
    fetchListCompany({ pageParam: 1 });
  };


  const onCompanySelect = (idCompany: string) => {
    setCompanyDetails({ id: idCompany });
    setParamHandle({ tabKey, page: 1 });
    setCompanyDisplayName(optionsListCompany.find((v) => v.id === idCompany)?.name || '');
  };

  const onCompanyClear = () => {
    setCompanyDetails({ id: '' });
    setCompanyDisplayName(null);
  };

  ////////     Filter by Company      ////////

  return (
    <div key={'MemberPanel' + tabKey}>
      <p className="filter-hub-text">Search</p>
      <div className="filter-hub-flex">
        <div className="filter-hub-options">
          {/* <div className="select">
            <SelectFilter
              handleSelect={handleChange}
              values={roleIds?.split(',') ?? []}
              optionSelect={listCompanyRolesData?.data.blueonionHubRoles ?? []}
            />
          </div> */}
          {!companyId &&
            <div className="select search-select">
              <SelectSearch
                optionsSelect={optionsListCompany}
                loading={isListCompanyLoading}
                onPopupScroll={handleScrollToBottom}
                onDropdownVisibleChange={onCompanyDropdownVisibleChange}
                onSelect={onCompanySelect}
                onClear={onCompanyClear}
                inputSearchProps={{
                  value: companyName,
                  onChange: onChangeCompanyInputSearch,
                }}
                placeholder="Search by Company"
                value={companyDisplayName}
              />
            </div>
          }
          <TextInput
            placeholder={'Search by Email or User Name'}
            className="input"
            inputProps={{
              onChange: onChangeInput,
              value: valueSearch,
              prefix: <SearchIcon width={20} height={20} />,
              maxLength: 255,
            }}
          />
        </div>
        <div className="clear-filters" onClick={ClearFilters}>
          <span>Clear all filters</span>
          <XCircleIcon width={24} height={24} />
        </div>
      </div>
      {isListMemberLoading ? (
        <Spin className="spin" />
      ) : (
        <>
          {!listMemberData?.data || listMemberData?.data.length === 0 ? (
            <NoDataTable />
          ) : (
            <>
              <Table
                rowKey={'id'}
                columns={columns}
                //dataSource={roleIds ? listCompanyData?.data?.data : []}
                dataSource={listMemberData?.data || []}
                pagination={false}
                onChange={onChangeTable}
                loading={isListMemberLoading}
                scroll={{ x: 1300 }}
              />

              <div className="pagination">
                <div className="total-results-text">
                  Total results: <p className="total-results-number">{totalResult}</p>
                </div>
                <CustomPagination
                  pageSize={listMemberData?.pageSize ?? 20}
                  current={listMemberData?.page ?? 1}
                  total={totalResult}
                  onChange={onChangePage}
                  showSizeChanger={true}
                />
              </div>
            </>
          )}
        </>
      )}

      {changePlanVisible && <ChangePlanModal
        visible={changePlanVisible}
        setVisible={setChangePlanVisible}
        memberDetails={memberDetails}
        parentDetails={memberDetails.company}
        listCompanyRoles={listCompanyRolesData}
        kioskTableData={kioskTableData}
        onKioskUpdateData={onKioskUpdateData}
        parentKioskData={companyKioskTableData}
        refetch={refetch}
        key={'external-member-change-plan'}
      />
      }

      {isOpenObjectRolesModal && (
        <ObjectRolesModal
          {...objectRolesModalArgs}
        />
      )}

      {isOpenObjectPermissionModal && (
        <ObjectPermissionModal
          {...objectPermissionModalArgs}
        />
      )}

    </div>
  );
}
